/** @format */

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  notification,
  Radio,
  Row,
  Tabs,
  Tag,
} from "antd";
import React from "react";
import { useState, useEffect } from "react";
import instance from "../../api/axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { FILE_URL } from "../../api/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  allOtherCertificateBall,
  interval_ball,
  parentCertificateBall,
} from "../../services/ball_interval";

const { TextArea } = Input;

const ConversationScoreView = () => {
  const [form] = Form.useForm();
  const location = useLocation()?.pathname;
  let a = location?.split("/");
  const [_id, setId] = useState();
  const { t } = useTranslation();
  const [universities, setUniversities] = useState([]);
  const [score, setScore] = useState();
  const [refresh, setRefresh] = useState();
  const [conversationScores, setConversationScores] = useState([]);
  const [totalScore, setTotalScore] = useState();
  const [general, setGeneral] = useState();
  const fieldsObj = [
    { name: "name", error: "error-name", title: "Ismi" },
    { name: "surname", error: "error-surname", title: "Familyasi" },
    {
      name: "middle_name",
      error: "error-middle_name",
      title: "Otasining ismi",
    },
    { name: "birthdate", error: "error-birth_date", title: "Tug'ulgan sana" },
    { name: "gender_name", error: "error-gender", title: "Jinsi" },
    { name: "region_name", error: "error-regions", title: "Viloyat" },
    { name: "district_name", error: "error-district", title: "Tuman" },
    { name: "address", error: "error-address", title: "Manzil" },
    { name: "telephone", error: "error-telephone", title: "Telefon 1" },
    { name: "telephone2", error: "error-telephone2", title: "Telefon 2" },
    {
      name: "passport_serie",
      error: "error-passport_serie",
      title: "Pasport seriyasi",
    },
    {
      name: "passport_numbers",
      error: "error-passport_numbers",
      title: "Pasport raqami",
    },
    {
      name: "passport_jshr",
      error: "error-passport_jshr",
      title: "Pasport JSHSHIR",
    },
    {
      name: "diploma_serie",
      error: "error-diploma_serie",
      title: "Diplom/Attestat seriyasi",
    },
    {
      name: "diploma_number",
      error: "error-diploma_number",
      title: "Diplom/Attestat raqam",
    },
    { name: "texnikum_name", error: "error-texnikum_id", title: "Texnikumi" },
    {
      name: "faculty_name",
      error: "error-faculty_id",
      title: "Ta`lim yo`nalishi",
    },
    {
      name: "tex_edu_type",
      error: "error-tex_edu_type",
      title: "Texnikum ta'lim shakli(bitirgan)",
    },
    {
      name: "education_language_name",
      error: "error-education_language_id",
      title: "Ta'lim tili",
    },
    {
      name: "education_type_name",
      error: "error-education_type_id",
      title: "Ta'lim shakli(topshirgan)",
    },
  ];
  const [childs, setChilds] = useState([]);

  // get applications data
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url:
            "/applications-for-texnikum-admin/" +
            a[a.length - 1] +
            "?expand=region,district,education_type,education_language,faculty,feedbacks_latest,universities,texnikum",
        });
        if (resp?.data?.status === "Success") {
          const data = resp?.data?.data;
          setId(data?.user_id);
          setGeneral(data);
          setUniversities(data?.universities);
          setScore(data?.texComissionBall?.ball);
          setConversationScores(data?.conversation_balls);

          let a = 0;
          data?.conversation_balls?.map((e) => {
            a += +e?.ball;
          });
          if (data?.conversation_balls?.length) {
            setTotalScore((a / data?.conversation_balls?.length).toFixed(2));
          }

          form.setFieldsValue({
            ...data,
            tex_edu_type:
              data?.tex_edu_type == 1 ? `${t("Sirtqi")}` : `${t("Kunduzgi")}`,
            gender_name: data?.gender === 0 ? "Female" : "Male",
            region_name: data?.region?.name,
            district_name: data?.district?.name,
            faculty_name: data?.faculty?.name[i18next.language],
            education_language_name:
              data?.education_language?.name[i18next.language],
            education_type_name: data?.education_type?.name[i18next.language],
            texnikum_name: data?.texnikum?.name[i18next.language],
          });
          const respOtherCertificates = await instance({
            method: "get",
            url: `certificate-categories?user_id=${data?.user_id}`,
          });
          setChilds(respOtherCertificates?.data?.data);
        }
      } catch (e) {
        message.error("Hujjatni ochishda xatolik!");
      }
    })();
  }, [i18next.language, refresh]);

  console.log("dsfsdjfhsdjfs", conversationScores);
  return (
    <>
      <Col span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink type="link" to={"/"}>
              {t("Asosiy")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink type="link" to={"/conversation_score"}>
              {t("Conversation score")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Suhbat baholari")}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <h5 className="header my-2">{t("O'quvchining suhbat baholari")}</h5>
      <Card className="mb-4 card">
        {conversationScores?.length ? (
          <Row gutter={16}>
            <Col span={24} className="mb-2">
              <strong>
                {t("O'rtacha ball")}: &nbsp; {totalScore}
              </strong>
            </Col>
            {conversationScores?.map((item, i) => (
              <Col
                key={i}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <div>
                  <span>{item?.texnikum_comission?.fio}: </span> &nbsp;
                  <strong>{item?.ball} ball</strong>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"O'quvchi baholanmagan"}
            />
          </div>
        )}
      </Card>
      <h5 className="header my-2">{t("O'quvchini malumotlari")}</h5>
      <Card className="card">
        <Form name="basic" form={form} layout="vertical">
          <Row gutter={24}>
            {fieldsObj?.map((value, i) =>
              (value.name === "diploma_serie" &&
                !form.getFieldValue(value.name)) ||
              (value.name === "diploma_number" &&
                !form.getFieldValue(value.name)) ? null : (
                <Col
                  key={i}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    style={{ width: "100%" }}
                    name={value.name}
                    label={t(value.title)}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              )
            )}
            <Col span={24} className="mb-3">
              <Divider className="mt-0" orientation="left">
                {t("Universitetlar")}
              </Divider>
              <Row gutter={16}>
                {universities?.length
                  ? universities?.map((univ, i) => (
                      <Col span={4} key={i}>
                        <Input readOnly value={univ?.name[i18next.language]} />
                      </Col>
                    ))
                  : null}
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
      <h5 className="header my-2">
        {t("O'quvchini ballari to'g'risida umumiy ma`lumot")}
      </h5>
      <Card className="card">
        <div className={"border p-2"}>
          <p>
            Suhbat bali: <b>{totalScore}</b>
          </p>
          <p>
            Natijaviy bali: <b>{totalScore}</b>
          </p>
        </div>
        <div className={"border p-2"}>
          <p>
            Fanlardan o'rtacha bali: <b>{general?.subjects_overall_score}</b>
          </p>
          <p>
            Natijaviy bali:{" "}
            <b>
              {interval_ball(
                general?.subjects_overall_score,
                parseInt(general?.tex_edu_type)
              )}
            </b>
          </p>
        </div>
        <div className={"border p-2"}>
          <p>
            Chet tili sertifikati darajasi:{" "}
            <b>
              {general?.language_certificate?.language_certificate_score?.score}
            </b>
          </p>
          <p>
            Natijaviy bali:{" "}
            <b>
              {general?.language_certificate?.language_certificate_score?.ball}
            </b>
          </p>
        </div>
        <div className={"border p-2"}>
          <p>Boshqa sertifikatlar ballari:</p>
          <div>
            {childs?.length > 0 &&
              childs?.map((el, index) => {
                return (
                  <div>
                    <h6>
                      {el?.name[i18next?.language]} :{" "}
                      <b>{parentCertificateBall(el)}</b>
                    </h6>
                    <div className={"ps-4"}>
                      {el?.childs?.map((elChild, indexChild) => {
                        return (
                          <p>
                            {elChild?.name[i18next?.language]} :
                            <b> {elChild?.scores[0]?.score}</b>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          <p>
            Natijaviy ball: <b>{allOtherCertificateBall(childs)}</b>
          </p>
        </div>
      </Card>
    </>
  );
};

export default ConversationScoreView;
