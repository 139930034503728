/** @format */

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  List,
  message,
  notification,
  Row,
  Spin,
  Tabs,
  Tag,
} from "antd";
import i18next from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsDot } from "react-icons/bs";
import { FaArrowLeft, FaCheck, FaTimes } from "react-icons/fa";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import instance from "../../api/axios";
import { FILE_URL } from "../../api/constants";
import EmptyData from "../../components/emptyData";

const StudentChecking = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [certificateCategory, setCertificateCategory] = useState([]);
  const path = useLocation()?.pathname;
  const a = path.split("/");
  const _id = a[a.length - 1];
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/texnikum-admin/student/details?user_id=${_id}&expand=certificates.category.parent,languageCertificate.languageCertificateScore,languageCertificate.languageCertificateCategory,application,scores`,
        });
        setData(resp?.data?.data);

        let subScore = {};
        resp.data?.data?.subjects?.forEach((e, i) => {
          subScore[e?.id] = e?.scores[0]?.score === null ? null : Number(e?.scores[0]?.score);
        });
        form.setFieldsValue({
          ...subScore,
        });

        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error("Malumot olishda xatolik!");
      }
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/certificate-categories`,
        });
        setCertificateCategory(resp?.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Malumot olishda xatolik!");
      }
    })();
  }, [refresh]);

  const onSubjectScore = async (values) => {
    console.log(values);
    setLoading(true);

    let bool = true;

    Object.entries(values).forEach(([key, value], i) => {
      // if(!((data?.subjects[i]?.course === 2 && val < 101 && val > 70) || (data?.subjects[i]?.course === 1 && val < 101 && val > 55) || value === '')) return bool = false;
      if(value > 100) return bool = false;
    })

    if(bool){
    let sub_data = { user_id: Number(_id), scores: [] };
    Object.entries(values).forEach(([key, value]) => {
      sub_data.scores.push({
        [`subject_id`]: Number(key),
        [`score`]: value,
      });
    });

    const res = await instance({
      method: "post",
      url: "/texnikum-admin/students/give-score",
      data: sub_data,
    });
    if (res.data.status === "Success") {
      form.resetFields();
      let subScore = {};
      res.data?.data?.forEach((e) => {
        subScore[e?.id] = e?.scores[0]?.score;
      });
      form.setFieldsValue({
        ...subScore,
      });
      setRefresh(!refresh);
      notification.success({
        message: t("Malumot saqlandi!"),
        description: t("Fanlar baholandi"),
      });
      setLoading(false);
    } else {
      message.error(t("Malumot saqlashda xatolik!"));
    }
  }else{
    setLoading(false);
    notification.error({
      message: t("Baholashda xatolik!"),
      description: t("Iltimos baholarni qaytadan tekshirib kuring!")
    })
  }
  };

  const onLangCertificate = async (id, status) => {
    const data = {
      [`user_id`]: Number(_id),
      [`language_certificate_id`]: Number(id),
      [`is_verified`]: Number(status),
    };
    await instance({
      method: "post",
      url: "/texnikum-admin/verify-language-certificate",
      data,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setRefresh(!refresh);
          notification.success({
            message: t("Malumot saqlandi!"),
            description: status
              ? t("Sertifikat tasdiqlandi!")
              : t("Sertifikat qaytarildi!"),
          });
        } else {
          message.error(t("Malumot saqlashda xatolik!"));
        }
      })
      .catch((error) => {
        message.error(t("Malumot saqlashda xatolik!"));
      });
  };

  const onCertificate = async (status) => {
    let cer_id = [];
    data?.user?.certificates?.forEach((item) => {
      cer_id.push(item.id);
    });

    const formdata = {
      [`user_id`]: Number(_id),
      [`certificates`]: cer_id,
      [`is_verified`]: Number(status),
    };

    await instance({
      method: "post",
      url: "/texnikum-admin/verify-certificate",
      data: formdata,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setRefresh(!refresh);
          notification.success({
            message: t("Malumot saqlandi!"),
            description: status
              ? t("Sertifikat tasdiqlandi!")
              : t("Sertifikat qaytarildi!"),
          });
        } else {
          message.error(t("Malumot saqlashda xatolik!"));
        }
      })
      .catch((error) => {
        message.error(t("Malumot saqlashda xatolik!"));
      });
  };

  return (
    <div>
      <div className='d-flex align-items-center'>
        <FaArrowLeft
          className='me-2 arrow-btn'
          onClick={() => history.goBack()}
        />
        <Breadcrumb className='my-2'>
          <Breadcrumb.Item>
            <NavLink type='link' to={"/"}>
              {t("Asosiy")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink type='link' to={"/talabalar_bazasi"}>
              {t("Student base")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {data?.user?.application?.surname} {data?.user?.application?.name}{" "}
            {data?.user?.application?.middle_name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Spin tip='Loading...' spinning={loading}>
        <Card className="mb-3">
          <Row gutter={[16,16]} >
            <Col span={24}>
              <h5> {t("Foydalanuvchi ma'lumotlar")}</h5>
            </Col>
            <Col span={12}>
              <strong>{t("FIO")}: </strong> {data?.user?.application?.surname} {data?.user?.application?.name} {data?.user?.application?.middle_name}
            </Col>
            <Col span={12}>
              <strong>{t("Tel")}: </strong> {data?.user?.application?.telephone}
            </Col>
            <Col span={12}>
              <strong>{t("Pasport seria va raqam")}: </strong> {data?.user?.application?.passport_serie} {data?.user?.application?.passport_numbers}
            </Col>
            <Col span={12}>
              <strong>{t("Diplom seria va raqam")}: </strong> {data?.user?.application?.diploma_serial} {data?.user?.application?.diploma_number}
            </Col>
            <Col span={12}>
              <strong>{t("Passport fayli")}: </strong> {
                <a
                href={
                  FILE_URL +
                  data?.user?.application
                    ?.folder_name +
                  "/" +
                  data?.user?.application?.passport_copy
                }
                target='_blank'
                className="text-decoration-underline ms-2">
                  {data?.user?.application?.passport_copy}
              </a>
              }
            </Col>
          </Row>
        </Card>
        <Card className='car'>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <div className=''>
                <div className='d-flex align-items-center'>
                  <h5>{t("Fanlar")}</h5>
                  <p className='ms-4 mb-1'>
                    {t("Status")}: &nbsp;{" "}
                    {data?.subjects?.length &&
                    data?.subjects[0]?.scores[0]?.score ? (
                      <Tag color='green'>{t("Baholangan")}</Tag>
                    ) : (
                      <Tag color='red'>{t("Baholanmagan")}</Tag>
                    )}
                  </p>
                </div>
                <Form layout='vertical' form={form}>
                  <Table className='table table-responsive table-bordered table-striped table-hover'>
                    <Thead>
                      <Tr>
                        <Th style={{ width: 40 }}>№</Th>
                        <Th>{t("Fan nomi")}</Th>
                        <Th>{t("Kurs")}</Th>
                        <Th>{t("Baholash")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.subjects?.length
                        ? data?.subjects.map((e, i) => (
                            <Tr key={i}>
                              <Td>{i + 1}</Td>
                              <Td>{e?.name[[i18next.language]]}</Td>
                              <Td>{e?.course}</Td>
                              <Td style={{ width: 120 }} className='py-1'>
                                <Form.Item
                                  className='p-0 m-0'
                                  style={{ margin: "0 auto" }}
                                  name={`${e?.id}`}
                                  rules={[{
                                    type: 'number',
                                    // min: (e?.course === 2 ? 71 : 56),
                                    max: 100,
                                    // message: t(`Ball ${e?.course === 2 ? 70 : 56} dan katta 100 dan kichik bo'lishi kerak!`)
                                    message: t(`Ball 100 dan kichik bo'lishi kerak!`)
                                  }]}
                                  >
                                  <InputNumber type="number" className='w-100'
                                    min={0}
                                    placeholder='Ball ...'
                                  />
                                </Form.Item>
                              </Td>
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                  <EmptyData data={data?.subjects} loading={loading} />
                  <div className='text-end'>
                    {data?.subjects?.length ? (
                      <Button htmlType='button' onClick={()=>onSubjectScore(form.getFieldsValue())} type='primary'>
                        {t("Saqlash")}
                      </Button>
                    ) : null}
                  </div>
                </Form>
              </div>
            </Col>
            <Col span={12}>
              <h5>{t("Chet tili sertifikati") + " (IELTS, TOEFL, CEFR)"}</h5>
              {data?.user?.language_certificate ? (
                <Row className='mt-3' gutter={[24, 24]}>
                  <Col span={16}>
                    <div
                      className='card'
                      style={{ width: "100%", height: 500 }}>
                      <iframe
                        src={
                          FILE_URL +
                          data?.user?.application?.folder_name +
                          "/" +
                          data?.user?.language_certificate.certificate
                        }
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: 5,
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    span={8}
                    className='d-flex flex-column justify-content-between'>
                    <div>
                      <p>
                        <strong>{t("O'quvchi sertfikati")}:</strong> &nbsp;{" "}
                        <span>
                          {
                            data?.user?.language_certificate
                              ?.language_certificate_category?.name
                          }
                        </span>
                      </p>
                      <p>
                        <strong>{t("Sertfikat bali")}:</strong> &nbsp;{" "}
                        <span>{data?.user?.language_certificate?.score}</span>
                      </p>

                      <p>
                        <strong>{t("Sertfikat darajasi")}:</strong> &nbsp;{" "}
                        <span>
                          {" "}
                          {
                            data?.user?.language_certificate
                              ?.language_certificate_score?.score
                          }
                        </span>{" "}
                        &nbsp;{"( "}
                        {Object.entries(
                          data?.user?.language_certificate
                            ?.language_certificate_score?.description
                        )?.map(([key, value], i) => (
                          <span key={i}>
                            {`${key}: ${value.join(" - ")} ${
                              i === 0 ? "," : ""
                            }`}{" "}
                            &nbsp;
                          </span>
                        ))}
                        {" ) "}
                      </p>
                      <p>
                        <strong>{t("Status")}: &nbsp; </strong>
                        {data?.user?.language_certificate?.verified_by ? (
                          data?.user?.language_certificate?.is_verified ? (
                            <Tag color='green'>{t("Qabul qilingan")}</Tag>
                          ) : (
                            <Tag color='red'>{t("Qaytarilgan")}</Tag>
                          )
                        ) : (
                          <Tag color='orange'>{t("Tekshirilmagan")}</Tag>
                        )}
                      </p>
                    </div>
                    <div className='text-end'>
                      <Button
                        type='primary'
                        danger
                        onClick={() =>
                          onLangCertificate(
                            data?.user?.language_certificate?.id,
                            0
                          )
                        }>
                        <FaTimes /> &nbsp; {t("Qaytarish")}
                      </Button>
                      <div>
                        <Button
                          htmlType='submit'
                          type='primary'
                          className='mt-3'
                          onClick={() =>
                            onLangCertificate(
                              data?.user?.language_certificate?.id,
                              1
                            )
                          }>
                          <FaCheck /> &nbsp; {t("Qabul qilish")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Empty description="Ma'lumot mavjud emas" />
              )}
            </Col>
          </Row>
        </Card>
        <Card className='car mt-3'>
          <div className='d-flex align-items-center'>
            <h5 className='mb-3'>{t("Sertifikat")}</h5>
            {data?.user?.certificates?.length ? (
              <p className='ms-4 mb-2'>
                {t("Status")}: &nbsp;{" "}
                {data?.user?.certificates[0]?.verified_by ? (
                  data?.user?.certificates[0]?.is_verified ? (
                    <Tag color='green'>{t("Qabul qilingan")}</Tag>
                  ) : (
                    <Tag color='red'>{t("Qaytarilgan")}</Tag>
                  )
                ) : (
                  <Tag color='orange'>{t("Tekshirilmagan")}</Tag>
                )}
              </p>
            ) : null}
          </div>
          {data?.user?.certificates?.length ? (
            <div>
              <div>
                {certificateCategory?.length
                  ? certificateCategory?.map((element, i) => (
                      <div key={i}>
                        <h6>
                          <strong>
                            {i + 1}. {element?.name[`${i18next.language}`]}
                          </strong>
                        </h6>
                        <List
                          size='small'
                          className='ms-3'
                          dataSource={element?.childs}
                          renderItem={(item) => (
                            <List.Item>
                              <div>
                                <div key={i}>
                                  {" "}
                                  <BsDot /> {item?.name[`${i18next.language}`]}{" "}
                                </div>
                                <p className='my-1'>
                                  <strong>
                                    Files:
                                    {data?.user?.certificates?.length
                                      ? data?.user?.certificates.map((e, i) =>
                                          e?.certificate_category_id ===
                                          item?.id ? (
                                            <a
                                              key={i}
                                              href={
                                                FILE_URL +
                                                data?.user?.application
                                                  ?.folder_name +
                                                "/" +
                                                e?.certificate
                                              }
                                              target='_blank'>
                                              <Tag
                                                className='ms-3'
                                                color='blue'>
                                                {e?.certificate}
                                              </Tag>
                                            </a>
                                          ) : null
                                        )
                                      : null}
                                  </strong>
                                </p>
                              </div>
                            </List.Item>
                          )}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <div className='d-flex justify-content-end mt-3'>
                <Button
                  type='primary'
                  danger
                  onClick={() => onCertificate(0)}
                  className='d-flex align-items-center justify-content-center mx-2'>
                  <FaTimes /> &nbsp;{t("Qaytarish")}
                </Button>
                <Button
                  htmlType='submit'
                  type='primary'
                  className='mx-2'
                  onClick={() => onCertificate(1)}>
                  <FaCheck /> &nbsp;{t("Qabul qilish")}
                </Button>
              </div>
            </div>
          ) : (
            <Empty description="Ma'lumot mavjud emas" />
          )}
        </Card>
      </Spin>
    </div>
  );
};

export default StudentChecking;
