import React from "react";
import { Form, Button, Divider, message, Spin } from "antd";
import "../login.scss";
import ReactInputMask from "react-input-mask";
import { Fragment, useState } from "react";
import instance from "../../../api/axios";
import { useHistory } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const Registration = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation();

  const onFinish = async (values) => {
    let data = new FormData();
    data.append("telephone", values?.telephone);
    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: "/send/sms/register",
        data: data,
      });

      history.push("/login");
    } catch (error) {
      if (!error?.response?.status) {
        setErrMsg("Serverda uzilish!");
      } else if (error?.response?.status == 400) {
        setErrMsg("Foydalanuvchi nomi yoki parol xato!");
      } else if (error?.response?.status == 401) {
        message.error("Bunday foydalanuvchi nomi va parol mavud emas!");
      } else if (error?.response?.status == 422) {
        form.setFields([
          {
            name: ["telephone"],
            errors: [...error?.response.data?.errors?.telephone],
          },
        ]);
      } else {
        setErrMsg("Tizimga kirishda xatolik!");
      }
      message.error("Telefon raqamni yuborishda xatolik!");
      setLoader(false);
    }
  };
  return (
    <Fragment>
      <Spin spinning={loader}>
        <div
          className="registration-page"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <Form
            form={form}
            name="registration-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <div className="registration-image">
              <RiAccountPinCircleLine
                className="fs-3"
                style={{ color: "#002355" }}
              />
              <Divider orientation="center">{t("Ro'yxatdan o'tish")}</Divider>
              <p>
                {t(
                  "Login parolni olish uchun tizimga telefon raqamingizni kiritng!"
                )}
              </p>
            </div>
            <Form.Item
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: `${t("Telefon raqamingizni kiriting!")}`,
                },
              ]}
              name={"telephone"}
              label={""}
            >
              <ReactInputMask
                placeholder="+998("
                className="registration-form-number ant-input"
                mask="+\9\9\8(99) 999 99 99"
                maskChar="_"
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="registration-form-button"
            >
              {t("Yuborish")}
            </Button>
            <Button
              onClick={() => history.goBack()}
              className="registration-form-back my-3"
              type="link"
            >
              <MdOutlineArrowBackIos /> {t("Ortga")}
            </Button>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
};

export default Registration;
