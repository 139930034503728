/** @format */

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  notification,
  Radio,
  Row,
  Tabs,
  Tag,
} from "antd";
import React from "react";
import { useState, useEffect } from "react";
import instance from "../../api/axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { FILE_URL } from "../../api/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const { TextArea } = Input;

const TexComissionView = () => {
  const [form] = Form.useForm();
  const location = useLocation()?.pathname;
  let a = location?.split("/");
  const [_id, setId] = useState();
  const { t } = useTranslation();
  const [universities, setUniversities] = useState([]);
  const [score, setScore] = useState();
  const [refresh, setRefresh] = useState()
  const [disabled, setDisabled] = useState()
  const [fieldsObj, setFieldsObj] = useState([
    { name: "name", error: "error-name", title: "Ismi" },
    { name: "surname", error: "error-surname", title: "Familyasi" },
    {
      name: "middle_name",
      error: "error-middle_name",
      title: "Otasining ismi",
    },
    { name: "birthdate", error: "error-birth_date", title: "Tug'ulgan sana" },
    { name: "gender_name", error: "error-gender", title: "Jinsi" },
    { name: "region_name", error: "error-regions", title: "Viloyat" },
    { name: "district_name", error: "error-district", title: "Tuman" },
    { name: "address", error: "error-address", title: "Manzil" },
    { name: "telephone", error: "error-telephone", title: "Telefon 1" },
    { name: "telephone2", error: "error-telephone2", title: "Telefon 2" },
    {
      name: "passport_serie",
      error: "error-passport_serie",
      title: "Pasport seriyasi",
    },
    {
      name: "passport_numbers",
      error: "error-passport_numbers",
      title: "Pasport raqami",
    },
    {
      name: "passport_jshr",
      error: "error-passport_jshr",
      title: "Pasport JSHSHIR",
    },
    {
      name: "diploma_serie",
      error: "error-diploma_serie",
      title: "Diplom/Attestat seriyasi",
    },
    {
      name: "diploma_number",
      error: "error-diploma_number",
      title: "Diplom/Attestat raqam",
    },
    {
      name: "faculty_name",
      error: "error-faculty_id",
      title: "Ta`lim yo`nalishi",
    },
    {
      name: "education_type_name",
      error: "error-education_type_id",
      title: "Ta'lim shakli",
    },
    {
      name: "education_language_name",
      error: "error-education_language_id",
      title: "Ta'lim tili",
    },
    { name: "texnikum_name", error: "error-texnikum_id", title: "Texnikumi" },
  ]);

  // get applications data
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url:
            "/applications-for-texnikum-comission/" +
            a[a.length - 1] +
            "?expand=region,district,education_type,education_language,faculty,feedbacks_latest,universities,texnikum",
        });
        if (resp?.data?.status === "Success") {
          const data = resp?.data?.data;
          setId(data?.user_id);
          setUniversities(data?.universities);
          setScore(data?.texComissionBall?.ball)
          setDisabled(data?.texnikum?.is_conversation_processing)
          form.setFieldsValue({
            ...data,
            gender_name: data?.gender === 0 ? "Female" : "Male",
            region_name: data?.region?.name,
            district_name: data?.district?.name,
            faculty_name: data?.faculty?.name[i18next.language],
            education_language_name:
              data?.education_language?.name[i18next.language],
            education_type_name: data?.education_type?.name[i18next.language],
            texnikum_name: data?.texnikum?.name[i18next.language],
          });
        }
      } catch (e) {
        message.error("Hujjatni ochishda xatolik!");
      }
    })();
  }, [i18next.language, refresh]);

  const onFinish = async () => {

    if(score <= 50){
    let data = {
      application_id: a[a.length - 1],
      ball: score
    }

    try {
      const resp = await instance({
        method: "post",
        url: "/store-ball-for-texnikum-comission",
        data: data,
      });
      if(resp.data.status == 'Success'){
        notification.success({
          message: t("Malumot saqlandi!"),
          // description:
        });
        setRefresh(!refresh);
      } else{
        notification.error({
          message: t("Malumot saqlashda xatolik!"),
          description: resp.data.message
        });
      }
    } catch (e) {
      message?.error("Malumot saqlashda xatolik!");
    }
  }else{
    message.error("Ball 50 dan kichik bo'lishi kerak")
  }
  }

  //   JSX
  return (
    <>
      <Col span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink type='link' to={"/"}>
              {t("Asosiy")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink type='link' to={"/tex_comission_ui"}>
              {t("Texnikum komissiya")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("O'quvchini baholash")}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col
        className='d-flex align-items-center'
        span={24}>
        <h5 className='header my-2'>{t("O'quvchining suhbat baholari")}</h5>
        {!disabled ? <Tag color="red" className="ms-4">{t("Baholash yopilgan")}</Tag> : null}
      </Col>
      <Card className="mb-4 card">
        <h6>Suhbat bahosi</h6>
        <InputNumber min={0} disabled={!disabled} value={score} placeholder="Suhbat bahosini kiriting ... " onChange={(e) => setScore(e)} style={{width: 400}} />
        <Button type="primary" className="ms-3" disabled={!disabled} onClick={onFinish} >{t('Saqlash')}</Button>
      </Card>
      <Card className="card" >
        <Form name='basic' form={form} layout='vertical'>
          <Row gutter={24}>
            {fieldsObj?.map((value, i) =>
              (value.name === "diploma_serie" &&
                !form.getFieldValue(value.name)) ||
              (value.name === "diploma_number" &&
                !form.getFieldValue(value.name)) ? null : (
                <Col
                  key={i}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}>
                  <Form.Item
                    style={{ width: "100%" }}
                    name={value.name}
                    label={t(value.title)}>
                    <Input readOnly />
                  </Form.Item>
                </Col>
              )
            )}
            <Col span={24} className='mb-3'>
              <Divider className='mt-0' orientation='left'>
                {t("Universitetlar")}
              </Divider>
              <Row gutter={16}>
                {universities?.length
                  ? universities?.map((univ, i) => (
                      <Col span={4} key={i}>
                        <Input readOnly value={univ?.name[i18next.language]} />
                      </Col>
                    ))
                  : null}
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default TexComissionView;
