/** @format */

import React from "react";
import "./applicant.scss";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  message,
  Select,
  DatePicker,
  Upload,
  Spin,
  notification,
  Tabs,
  List,
  Result,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import instance from "../../api/axios";
import ReactInputMask from "react-input-mask";
import { BsUpload } from "react-icons/bs";
import { FaPlus, FaCertificate } from "react-icons/fa";
import moment from "moment";
import { FILE_URL } from "../../api/constants";
import { useHistory, useLocation } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import jshrImage from "../../assets/images/jshr.jpg";
import { Fragment } from "react";
import { MdOutlineClose } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";
import { FaPassport, FaBookReader, FaUniversity } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import AllCertificatesCrudTab from "../certificates/AllCertificatesCrudTab";
import { getErrorTab, getTabInputNames } from "../../services/validator_helper";
import { useSelector } from "react-redux";

const { Option } = Select;
const { TabPane } = Tabs;

function handleChange(value) {}

const ApplyDocs = () => {
  // Hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [languageCertificateForm] = Form.useForm();
  const [otherCertificateForm] = Form.useForm();
  const [faculties, setFaculties] = useState([]);
  const [facultyItem, setFacultyItem] = useState([]);
  const [eduLangs, setEduLangs] = useState([]);
  const [eduTypes, setEduTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const formatDate = "YYYY/MM/DD";
  const [loader, setLoader] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [texnikum, setTexnikum] = useState();
  const [university, setUniversity] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [reloadCertificateTab, setReloadCertificateTab] = useState(false);

  const path = useLocation()?.pathname;
  const a = path.split("/");
  const acTab = a[a.length - 1] == 4 ? "4" : "1";
  const { t } = useTranslation();
  const [disableName, setDisableName] = useState([
    "name",
    "surname",
    "middle_name",
    "birth_date",
    "gender",
    "region_id",
    "district_id",
    "address",
    "telephone",
    "telephone2",
    "photo",
    "passport_serie",
    "passport_numbers",
    "passport_jshr",
    "passport_copy",
    "diploma_serie",
    "diploma_number",
    "diploma_copy",
    "faculty_id",
    "education_type_id",
    "education_language_id",
    "admission_type_id",
    "diploma_given",
    "direction_id",
    "tex_edu_type",
    "texnikum_id",
    "university_id",
  ]);
  const [activeTab, setActiveTab] = useState(acTab);
  const auth = useSelector((state) => state.authRedux?.auth);
  const isActive = auth?.admission_active;

  const [fileUrls, setFileUrls] = useState({
    img: [],
    passport: [],
    diplom: [],
    sertificate: [],
  });

  // Form data functions
  const onFinish = async (values) => {
    form.validateFields().then(async (data) => {
      try {
        setLoader(true);
        const data = new FormData();
        Object.entries(values)?.forEach(([key, value]) => {
          if (value !== undefined) {
            if (
              [
                "passport_copy",
                "photo",
                "diploma_copy",
                "qayta_tayyorlov_sertifikat",
              ].includes(key)
            ) {
              if (value?.file?.originFileObj)
                data.append(key, value?.file?.originFileObj);
            } else if (key === "birth_date") {
              data.append("birthdate", moment(value).format(formatDate));
            } else if (key === "diploma_given") {
              data.append(key, moment(value).format(formatDate));
            } else {
              if (key != "university_id") {
                data.append(key, value);
              }
            }
          }
        });
        if (selectedUniversity?.length < 6) {
          form.setFields([
            {
              name: "university_id",
              value: null,
              errors: [t("Barcha universitetlarni tanlang!")],
            },
          ]);
        } else {
          data.append("universities", JSON.stringify(selectedUniversity));

          // post APi
          const response = await instance({
            url: applicantInfos?.id
              ? `/application/update/${applicantInfos.id}`
              : `/application/store`,
            method: "post",
            data: data,
            headers: {
              Accept: "application/json",
            },
          });
          if (response?.status === 200) {
            message.success(t("Ariza saqlandi"));
            setActiveTab("4");
          }
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        form.setFields(
          Object.entries(error.response.data.errors).map(([key, value]) => {
            return {
              name: key,
              errors: value,
            };
          })
        );
        let tabKey = 100;
        Object.entries(error.response.data.errors).map(([key, value]) => {
          let ErrTabKey = getErrorTab(key);
          if (ErrTabKey < tabKey) {
            tabKey = ErrTabKey;
          }
        });
        if (tabKey <= 4) {
          setActiveTab(tabKey);
        }
        notification["error"]({
          message: "Hujjatni yuborishda xatolik!",
          placement: "topRight",
          duration: 2,
        });
      }
    });
  };

  // get texnikum
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/texnikum",
        });
        setTexnikum(resp?.data?.data);
      } catch (e) {
        message.error(e?.response?.data?.message);
      }
    })();
  }, []);

  // get universities
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/university",
        });
        setUniversity(resp?.data?.data);
      } catch (e) {
        message.error(e?.response?.data?.message);
      }
    })();
  }, []);

  // get application infos
  const [applicantInfos, setApplicantInfos] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/application?expand=region,education_language,education_type,universities,feedbacks",
        });
        let data = resp?.data?.data;
        if (data?.texStatus?.status == 1 || data?.texStatus?.status == 3) {
          setIsDisabled(false);
        } else {
          setIsDisabled(false);
        }
        let arrUniver = [];
        data?.universities?.map((element) => {
          arrUniver.push(element.id);
        });
        setSelectedUniversity(arrUniver);
        setApplicantInfos(data);
        if (data) {
          if (data?.region_id) {
            handleRegions(data?.region_id, true);
          }
          _getFacultyItem(data?.faculty_id);
          form.setFieldsValue({
            // ...data,
            name: data?.name,
            surname: data?.surname,
            middle_name: data?.middle_name,
            address: data?.address,
            gender: data?.gender,
            region_id: data?.region_id,
            district_id: data?.district_id,
            telephone: data?.telephone,
            telephone2: data?.telephone2,
            passport_serie: data?.passport_serie,
            passport_numbers: data?.passport_numbers,
            passport_jshr: data?.passport_jshr,
            diploma_number: data?.diploma_number,
            diploma_serie: data?.diploma_serie,
            diploma_given: moment(data?.diploma_given),
            texnikum_id: data?.texnikum_id,
            birth_date: moment(data?.birthdate),
            faculty_id: data?.faculty_id,
            education_language_id: data?.education_language_id,
            education_type_id: data?.education_type_id,
            photo: data?.photo,
            passport_copy: data?.passport_copy,
            diploma_copy: data?.diploma_copy,
            tex_edu_type: data?.tex_edu_type,
          });
          // setSelectedType(data?.admission_type_id);
          setFileUrls({
            img: [
              {
                uid: "1",
                name:
                  "image." +
                  data?.photo?.substring(
                    data?.photo?.lastIndexOf(".") + 1,
                    data?.photo?.length
                  ),
                ststus: "success",
                url: FILE_URL + data?.folder_name + "/" + data?.photo,
              },
            ],
            passport: [
              {
                uid: "1",
                name:
                  "passport." +
                  data?.passport_copy?.substring(
                    data?.passport_copy?.lastIndexOf(".") + 1,
                    data?.data?.passport_copy?.length
                  ),
                ststus: "success",
                url: FILE_URL + data?.folder_name + "/" + data?.passport_copy,
              },
            ],
            diplom: [
              {
                uid: "1",
                name:
                  "diplom." +
                  data?.diploma_copy?.substring(
                    data?.diploma_copy?.lastIndexOf(".") + 1,
                    data?.diploma_copy?.length
                  ),
                ststus: "success",
                url: FILE_URL + data?.folder_name + "/" + data?.diploma_copy,
              },
            ],
            sertificate: [
              {
                uid: "1",
                name:
                  "sertificate." +
                  data?.qayta_tayyorlov_sertifikat?.substring(
                    data?.qayta_tayyorlov_sertifikat?.lastIndexOf(".") + 1,
                    data?.qayta_tayyorlov_sertifikat?.length
                  ),
                ststus: "success",
                url:
                  FILE_URL +
                  data?.folder +
                  "/" +
                  data?.qayta_tayyorlov_sertifikat,
              },
            ],
          });
          let errors = [];
          let disNames = [];
          if (resp?.data?.data?.status !== 3) {
            resp?.data?.data?.feedbacks.forEach((item) => {
              errors = [
                ...errors,
                {
                  name: item?.column_name,
                  errors: [item?.feedback],
                  validating: false,
                },
              ];
              disNames = [...disNames, item?.column_name];
            });
          }
          form.setFields(errors);
          setDisableName(disNames);
        }
      } catch (e) {
        message.error("Error get your application");
      }
    })();
  }, []);

  // upload files
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // get faculties with items
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/faculties",
        });
        if (resp?.status === 200) {
          setFaculties(resp?.data?.faculties);
        }
      } catch (e) {
        message.error("Fakultetni ochishda xatolik!!");
      }
    })();
  }, []);

  const _getFacultyItem = async (id) => {
    if (id) {
      try {
        const resp = await instance({
          method: "get",
          url: `/faculties/${id}?expand=education_languages,education_types`,
        });
        if (resp?.status === 200) {
          setFacultyItem(resp?.data?.faculty);
        }
      } catch (e) {
        message.error("Ta'lim turi ma'lumotlarini ochishda xatolik!");
      }
    }
  };

  // get address
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/regions",
        });
        if (resp?.status === 200) {
          setRegions(resp?.data?.data);
        }
      } catch (e) {
        message.error("Viloyatni ochishda xatolik!");
      }
    })();
  }, []);

  const handleRegions = async (event, type) => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/districts/" + event,
        });
        if (resp?.status === 200) {
          setDistricts(resp?.data?.data);
          if (!type) {
            form.setFieldsValue({
              district: null,
            });
          }
        }
      } catch (e) {
        message.error("Tumanlarni ochishda xatolik!");
      }
    })();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const uploadButton = (
    <div>
      <FaPlus />
      <div style={{ marginTop: 8 }}>{t("Yuklash")}</div>
    </div>
  );

  const validateTab = () => {
    form
      .validateFields()
      .then((err, values) => {})
      .catch((info) => {});
  };
  const clickSaveButton = () => {
    form.submit();
  };
  const clickCertificateSaveButton = () => {
    languageCertificateForm.submit();
  };
  const clickTabButton = () => {
    // let needs = getTabInputNames(activeTab);
    // form.validateFields().then((err, values) => {
    //   if (!err) {
    //     setActiveTab(`${parseInt(activeTab) + 1}`);
    //   }
    // });
    setActiveTab(`${parseInt(activeTab) + 1}`);
  };
  return (
    <Fragment>
      <Spin spinning={loader}>
        {/* {!isActive ? (
          <Result
            className="mt-5"
            status="warning"
            title={t("Hujjat topshrish yakunlangan !!!")}
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => history.push("/")}
              >
                {t("Asosiyga qaytish")}
              </Button>
            }
          />
        ) : (
        )} */}
        <>
          <Form
            scrollToFirstError={true}
            style={{ backgroundColor: "#fff" }}
            rounded={true}
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Tabs
              className="mx-3"
              activeKey={activeTab}
              onChange={(e) => {
                setActiveTab(e);
                window.scrollTo(0, 0);
              }}
              // defaultActiveKey="1"
            >
              <TabPane
                tab={
                  <span>
                    {" "}
                    {t("Shaxsiy ma'lumotlar")} <RiFileUserLine />
                  </span>
                }
                key="1"
              >
                <Row className="personel-infos p-1 p-md-3" gutter={[16, 8]}>
                  {/* fio */}
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="applicantImgWrapper">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Iltimos maydoni to'ldiring!",
                          },
                        ]}
                        name={"photo"}
                        label={
                          t("Hujjat topshiruvchining rasmi") + " (JPEG,PNG,JPG)"
                        }
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Upload.Dragger
                          disabled={!disableName.includes("photo")}
                          accept=".jpeg,.png,.jpg"
                          listType="picture"
                          fileList={
                            fileUrls?.img?.length > 0 ? fileUrls?.img : []
                          }
                          customRequest={dummyRequest}
                          onChange={(e) => {
                            setFileUrls({
                              ...fileUrls,
                              img: [
                                {
                                  id: "-1",
                                  name: e?.file?.name,
                                  status: "success",
                                  url: window.URL.createObjectURL(
                                    e?.file?.originFileObj
                                  ),
                                },
                              ],
                            });
                          }}
                        >
                          {fileUrls.img?.length > 0 ? (
                            <img
                              className="applicantImg"
                              src={fileUrls?.img[0]?.url}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload.Dragger>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 16 }}
                    xl={{ span: 16 }}
                  >
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Iltimos maydoni to'ldiring!",
                            },
                          ]}
                          name={"name"}
                          label={t("Ismi")}
                        >
                          <Input
                            disabled={!disableName.includes("name")}
                            placeholder={t("ismi")}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Iltimos maydoni to'ldiring!",
                            },
                          ]}
                          name={"surname"}
                          label={t("Familiyasi")}
                        >
                          <Input
                            disabled={!disableName.includes("surname")}
                            placeholder={t("familiyasi")}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          name={"middle_name"}
                          label={t("Otasining ismi")}
                        >
                          <Input
                            disabled={!disableName.includes("middle_name")}
                            placeholder={t("otasining ismi")}
                          />
                        </Form.Item>
                      </Col>
                      {/* Bith date */}
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Iltimos maydoni to'ldiring!",
                            },
                          ]}
                          name={"birth_date"}
                          label={t("Tug'ilgan sanasi")}
                        >
                          <DatePicker
                            format={formatDate}
                            disabled={!disableName.includes("birth_date")}
                            style={{ width: "100%" }}
                            placeholder={t("tug'ulgan sanasi")}
                          />
                        </Form.Item>
                      </Col>
                      {/* Gender */}
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Iltimos maydoni to'ldiring!",
                            },
                          ]}
                          name={"gender"}
                          label={t("Jinsi")}
                        >
                          <Select
                            disabled={!disableName.includes("gender")}
                            style={{ width: "100%" }}
                            placeholder={t("jinsi")}
                            onChange={handleChange}
                            allowClear
                          >
                            <Option value={1}>{t("Erkak")}</Option>
                            <Option value={0}>{t("Ayol")}</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* Address */}
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        {" "}
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Iltimos maydoni to'ldiring!",
                            },
                          ]}
                          name={"region_id"}
                          label={t("Viloyat")}
                        >
                          <Select
                            disabled={!disableName.includes("region_id")}
                            allowClear
                            style={{ width: "100%" }}
                            placeholder={t("viloyat")}
                            onChange={(e) => handleRegions(e)}
                          >
                            {regions?.map((item, i) => (
                              <Select.Option key={i} value={item?.id}>
                                {item?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"district_id"}
                      label={t("Tuman")}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder={t("tuman")}
                      >
                        {districts?.map((item, i) => (
                          <Select.Option key={i} value={"" + item?.id}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"address"}
                      label={t("Manzil")}
                      style={{ width: "100%" }}
                    >
                      <Input
                        disabled={!disableName.includes("address")}
                        placeholder={t("manzil")}
                      />
                    </Form.Item>
                  </Col>
                  {/* Contact */}
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    {" "}
                    <Form.Item
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"telephone"}
                      label={t("Telefon 1")}
                    >
                      <ReactInputMask
                        disabled={!disableName.includes("telephone")}
                        placeholder="+998("
                        className="ant-input"
                        mask="+\9\9\8(99) 999 99 99"
                        maskChar="_"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    {" "}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"telephone2"}
                      label={t("Telefon 2")}
                    >
                      <ReactInputMask
                        disabled={!disableName.includes("telephone2")}
                        placeholder="+998("
                        className="ant-input"
                        mask="+\9\9\8(99) 999 99 99"
                        maskChar="_"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              <TabPane
                tab={
                  <span>
                    {" "}
                    {t("Pasport va diplom ma'lumotlar")} <FaPassport />
                  </span>
                }
                key="2"
              >
                {/* Passport and diplom infos Row */}
                <Row className="other-infos p-1 p-md-3 m-0" gutter={[16, 8]}>
                  {/* Passport infos Col */}
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Iltimos maydoni to'ldiring!",
                                },
                              ]}
                              name={"passport_serie"}
                              label={t("Pasport seriyasi")}
                            >
                              <ReactInputMask
                                disabled={
                                  !disableName.includes("passport_serie")
                                }
                                placeholder="- -"
                                className="ant-input text-uppercase"
                                mask=""
                                maskChar="-"
                                maxLength={2}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Iltimos maydoni to'ldiring!",
                                },
                              ]}
                              name={"passport_numbers"}
                              label={t("Pasport raqami")}
                            >
                              <ReactInputMask
                                disabled={
                                  !disableName.includes("passport_numbers")
                                }
                                placeholder="- - - - - - -"
                                className="ant-input"
                                mask="9999999"
                                maskChar="-"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}
                          >
                            {" "}
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Iltimos maydoni to'ldiring!",
                                },
                              ]}
                              name={"passport_jshr"}
                              label={t("Pasport JSHSHIR")}
                            >
                              <ReactInputMask
                                disabled={
                                  !disableName.includes("passport_jshr")
                                }
                                placeholder="- - - - - - - - - - - - - -"
                                className="ant-input"
                                mask="99999999999999"
                                maskChar="-"
                              />
                            </Form.Item>
                          </Col>
                          {/* Passport file */}
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Iltimos maydoni to'ldiring!",
                                },
                              ]}
                              name={"passport_copy"}
                              label={t("Pasport rangli nusxasi") + " (PDF)"}
                            >
                              <Upload.Dragger
                                disabled={
                                  !disableName.includes("passport_copy")
                                }
                                accept=".pdf"
                                listType="picture"
                                fileList={fileUrls?.passport}
                                onChange={(e) => {
                                  setFileUrls({
                                    ...fileUrls,
                                    passport: [
                                      {
                                        id: "-1",
                                        name: e?.file?.name,
                                        status: "success",
                                        url: window.URL.createObjectURL(
                                          e?.file?.originFileObj
                                        ),
                                      },
                                    ],
                                  });
                                }}
                                customRequest={dummyRequest}
                              >
                                <Button
                                  disabled={
                                    !disableName.includes("passport_copy")
                                  }
                                  icon={<BsUpload />}
                                >
                                  {t("Yuklash")}
                                </Button>
                              </Upload.Dragger>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Row gutter={[8, 8]}>
                          <img
                            className="img-fluid mt-3"
                            src={jshrImage}
                            alt="networ error!"
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  {/* Diplom infos Col */}
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                  >
                    <h3 className="text-center">{t("Diplom ma'lumotlar")}</h3>
                    <Row gutter={[16, 16]}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 24 }}
                          >
                            <Form.Item
                              name={"diploma_serie"}
                              label={t("Diplom/Attestat seriyasi")}
                            >
                              <ReactInputMask
                                disabled={
                                  !disableName.includes("diploma_serie")
                                }
                                className="ant-input text-uppercase"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 24 }}
                          >
                            <Form.Item
                              name={"diploma_number"}
                              label={t("Diplom/Attestat raqam")}
                            >
                              <ReactInputMask
                                disabled={
                                  !disableName.includes("diploma_number")
                                }
                                className="ant-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name={"diploma_given"}
                              label={t("Diplom/Attestat berilgan sana")}
                            >
                              <DatePicker
                                format={formatDate}
                                style={{ width: "100%" }}
                                disabled={
                                  !disableName.includes("diploma_given")
                                }
                                placeholder={t("sanani tanlang")}
                              />
                            </Form.Item>
                          </Col>
                          {/* Diplom file */}
                          <Col span={24}>
                            <Form.Item
                              name={"diploma_copy"}
                              label={
                                t("Diplom/Attestat va ilova nusxasi") + " (PDF)"
                              }
                            >
                              <Upload.Dragger
                                disabled={isDisabled}
                                fileList={fileUrls?.diplom}
                                accept=".pdf"
                                listType="picture"
                                customRequest={dummyRequest}
                                onChange={(e) => {
                                  setFileUrls({
                                    ...fileUrls,
                                    diplom: [
                                      {
                                        id: "-1",
                                        name: e?.file?.name,
                                        status: "success",
                                        url: window.URL.createObjectURL(
                                          e?.file?.originFileObj
                                        ),
                                      },
                                    ],
                                  });
                                }}
                              >
                                <Button
                                  disabled={
                                    !disableName.includes("diploma_copy")
                                  }
                                  icon={<BsUpload />}
                                >
                                  {t("Yuklash")}
                                </Button>
                              </Upload.Dragger>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>

              <TabPane
                tab={
                  <span>
                    {" "}
                    {t("Ta'lim ma'lumotlar")} <FaBookReader />
                  </span>
                }
                key="3"
              >
                {/* Education infos Row */}
                <Row className="education-infos p-1 p-md-3" gutter={[16, 8]}>
                  {/* Education infos */}

                  <Col xs={24} md={12}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"texnikum_id"}
                      label={t("Texnikum tanlash(majburiy)")}
                    >
                      <Select
                        disabled={!disableName.includes("texnikum_id")}
                        placeholder={t("Texnikumni tanlang")}
                      >
                        {texnikum?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {item?.name[i18next.language]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"tex_edu_type"}
                      label={t("Texnikum ta'lim shakli")}
                    >
                      <Select
                        disabled={!disableName.includes("tex_edu_type")}
                        style={{ width: "100%" }}
                        placeholder={t("ta'lim shakli")}
                        onChange={handleChange}
                        allowClear
                      >
                        <Option value={1}>{t("Sirtqi")}</Option>
                        <Option value={0}>{t("Kunduzgi")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Select Universities */}
                  <Col span={24}>
                    <Form.Item
                      name={"university_id"}
                      label={t(
                        "Universitetlarni ketma ketlikda tanlang!(majburiy)"
                      )}
                    >
                      <Select
                        disabled={!disableName.includes("university_id")}
                        onChange={(e) => {
                          setSelectedUniversity([...selectedUniversity, e]);
                          form.setFieldsValue({
                            university_id: null,
                          });
                        }}
                        style={{ width: "100%" }}
                        placeholder={t("universitet tanlash")}
                        allowClear
                      >
                        {university?.map((item, i) => (
                          <Select.Option
                            disabled={selectedUniversity.includes(item?.id)}
                            value={item?.id}
                            key={i}
                          >
                            {item?.name[i18next.language]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="selectedTypeWrapper" span={24}>
                    <List
                      header={
                        <div className="d-flex justify-content-between justify-content-md-around align-items-center">
                          {t("Tanlagan universitetlarim ro'yxati")}
                          <FaUniversity className="fs-5 text-primary" />
                        </div>
                      }
                      size="small"
                      dataSource={selectedUniversity}
                      renderItem={(item, i) => {
                        return (
                          <List.Item
                            disabled={selectedUniversity.includes(item?.id)}
                            className="d-md-flex justify-content-between justify-content-center"
                          >
                            <div
                              className="textUniversity"
                              style={{ width: "90%" }}
                            >
                              {i + 1}{" "}
                              {
                                university?.filter(
                                  (item1) => item1?.id == item
                                )[0]?.name[i18next?.language]
                              }
                            </div>

                            <Button
                              className="m-0 p-0 denyUniversity"
                              style={{ width: "10%" }}
                              size="small"
                              onClick={() =>
                                setSelectedUniversity(
                                  selectedUniversity?.filter(
                                    (item2) => item !== item2
                                  )
                                )
                              }
                              disabled={isDisabled}
                              type="dashed"
                              danger
                              ghost
                            >
                              <MdOutlineClose size={18} />
                            </Button>
                          </List.Item>
                        );
                      }}
                    />
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"faculty_id"}
                      label={t("Fakultet tanlash")}
                    >
                      <Select
                        disabled={!disableName.includes("faculty_id")}
                        style={{ width: "100%" }}
                        placeholder={t("ta`lim yo`nalishi")}
                        onChange={(e) => {
                          _getFacultyItem(e);
                          form.setFieldsValue({
                            education_language_id: null,
                            education_type_id: null,
                          });
                        }}
                      >
                        {faculties?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {item?.name[i18next.language]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"education_language_id"}
                      label={t("Ta'lim tili")}
                    >
                      <Select
                        disabled={
                          !disableName.includes("education_language_id")
                        }
                        placeholder={t("ta'lim tili")}
                      >
                        {facultyItem?.education_languages?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {item?.name[i18next.language]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Iltimos maydoni to'ldiring!",
                        },
                      ]}
                      name={"education_type_id"}
                      label={t("Ta'lim shakli")}
                    >
                      <Select
                        disabled={!disableName.includes("education_type_id")}
                        placeholder={t("ta'lim shakli")}
                      >
                        {facultyItem?.education_types?.map((item, i) => (
                          <Select.Option key={i} value={item?.id}>
                            {item?.name[i18next.language]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    {" "}
                    {t("Sertifikatlar")} <FaCertificate />
                  </span>
                }
                key="4"
              >
                <AllCertificatesCrudTab
                  reloadCertificateTab={reloadCertificateTab}
                  otherCertificateForm={otherCertificateForm}
                  languageCertificateForm={languageCertificateForm}
                  applicantInfos={applicantInfos}
                  clickCertificateSaveButton={clickCertificateSaveButton}
                />
              </TabPane>
            </Tabs>
          </Form>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "right",
              }}
              className="my-3"
            >
              <Button
                style={{
                  margin: "0 8px",
                  display: activeTab === "1" ? "none" : "",
                }}
                size="small"
                htmlType="button"
                className="m-1"
                type="primary"
                onClick={() => {
                  setActiveTab(`${parseInt(activeTab) - 1}`);
                }}
              >
                {t("Orqaga")}
              </Button>

              {activeTab == "3" && (
                <Button
                  style={{
                    margin: "0 8px",
                    display:
                      activeTab !== "3" && activeTab !== "4" ? "none" : "",
                  }}
                  size="small"
                  disabled={
                    isDisabled || (activeTab !== "3" && activeTab !== "4")
                  }
                  type="primary"
                  onClick={() => {
                    clickSaveButton();
                  }}
                >
                  {t("Saqlash")}
                </Button>
              )}
              {/*{activeTab == "4" && (*/}
              {/*  <Button*/}
              {/*    style={{*/}
              {/*      margin: "0 8px",*/}
              {/*      display: activeTab !== "3" && activeTab !== "4" ? "none" : "",*/}
              {/*    }}*/}
              {/*    size="small"*/}
              {/*    disabled={*/}
              {/*      isDisabled || (activeTab !== "3" && activeTab !== "4")*/}
              {/*    }*/}
              {/*    className="m-1"*/}
              {/*    type="primary"*/}
              {/*    onClick={() => {*/}
              {/*      clickCertificateSaveButton();*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {t("Sertifikatlarni saqlash")}*/}
              {/*  </Button>*/}
              {/*)}*/}

              <Button
                style={{
                  margin: "0 8px",
                  display: activeTab === "3" || activeTab === "4" ? "none" : "",
                }}
                size="small"
                htmlType="button"
                disabled={activeTab === "3" || activeTab === "4"}
                type="primary"
                onClick={() => {
                  clickTabButton();
                }}
              >
                {t("Keyingi")}
              </Button>
            </Col>
          </Row>
        </>
      </Spin>
    </Fragment>
  );
};

export default ApplyDocs;
