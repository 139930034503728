import React from 'react'

const NonLayout=({children})=>{
    return(
        <div>
            {
                children
            }
        </div>
    )
}

export default NonLayout