import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import {
  Breadcrumb,
  Button,
  Col,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import instance from "../../api/axios";
import { Fragment } from "react";

const Texnikumlar = () => {
  //hooks
  const [texnikum, setTexnikum] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  // popconfirm
  const [visible, setVisible] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = (id) => {
    setVisible(id);
  };

  const handleCancel = () => {
    setVisible("");
  };

  // delete subjects
  const delete_texnikum = async (id) => {
    try {
      const resp = await instance({
        method: "delete",
        url: `/texnikum/${id}`,
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error("Ma`lumotni o`chirishda xatolik!");
      setVisible("");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: "/texnikum",
        });
        if (resp?.status === 200) {
          setTexnikum(resp?.data?.data);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        message.error("Error read education texnikum!");
      }
    })();
  }, [refresh]);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("Texnikumlar")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col
          className="d-flex align-content-center justify-content-between"
          span={24}
        >
          <h3>{t("Texnikumlar")}</h3>
          <Button type="primary">
            <NavLink to={"/create_texnikum"}>{t("Qo'shish")} +</NavLink>
          </Button>
        </Col>

        <Skeleton loading={loading} active round={true}>
          <Table
            loading={true}
            className="table table-responsive table-bordered table-striped table-hover text-start"
          >
            <Thead className="table-head text-center">
              <Tr>
                <Th>№</Th>
                <Th>{t("Nomi uz")}</Th>
                <Th>{t("Nomi ru")}</Th>
                <Th>{t("Tavsif uz")}</Th>
                <Th>{t("Tavsif ru")}</Th>
                <Th>{t("Holati")}</Th>
                <Th>{t("Amallar")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {texnikum?.map((v, i) => {
                return (
                  <Tr>
                    <Td className="text-center">{i + 1}</Td>
                    <Td>{v?.name?.uz}</Td>
                    <Td>{v?.name?.ru}</Td>
                    <Td>{v?.description?.uz}</Td>
                    <Td>{v?.description?.ru}</Td>
                    <Td className="text-center">
                      <Switch
                        checkedChildren={t("Faol")}
                        unCheckedChildren={t("Faol emas")}
                        size="small"
                        checked={v?.is_active === 1}
                      ></Switch>
                    </Td>
                    <Td className="d-flex justify-content-around align-items-center m-0 p-0">
                      <NavLink
                        className=""
                        to={"/read_texnikum/" + v?.id}
                        type="link"
                      >
                        <FaEye />
                      </NavLink>

                      <NavLink to={"/edit_texnikum/" + v?.id} type="link">
                        <FaEdit />
                      </NavLink>

                      <Popconfirm
                        title={t("Ma'lumotni o'chirishni tasdiqlaysizmi?")}
                        okText={t("Ha")}
                        cancelText={t("Yo'q")}
                        visible={visible == v?.id}
                        onConfirm={() => delete_texnikum(v?.id)}
                        okButtonProps={{
                          loading: confirmLoading,
                        }}
                        onCancel={handleCancel}
                      >
                        <Button
                          onClick={() => showPopconfirm(v.id)}
                          className="text-danger m-0 p-0"
                          type="link"
                        >
                          <FaTrashAlt />
                        </Button>
                      </Popconfirm>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Skeleton>
      </Row>
    </Fragment>
  );
};

export default Texnikumlar;
