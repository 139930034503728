import React from "react";
import { Card, Layout } from "antd";
import { Fragment } from "react";
import "./content.scss";

const { Content } = Layout;
const CustomContent = ({ children }) => {
  return (
    <Fragment>
      <Card
        className="border-0 w-100 h-100 shadow  content-card-general"
        style={{ backgroundColor: "#fff", marginTop: 50 }}
      >
        {" "}
        {children}
      </Card>
    </Fragment>
  );
};

export default CustomContent;
