import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import instance from "../../../api/axios";
import "../educationtype.scss";

const EditEduTypes = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const url = useLocation().pathname;
  let a = url.split("/");
  const { t } = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };

    try {
      setLoader(true);
      const resp = await instance({
        method: "put",
        url: `/edu-types/${a[a.length - 1]}`,
        data: values,
      });
      history.push("/education_types");
    } catch (error) {
      message.error("Talim shaklini saqlashda xatolik!");
      setLoader(false);
    }
  };

  // get education types
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/edu-types/${a[a.length - 1]}`,
        });
        if (resp?.status == 200) {
          setLoading(false);
          form.setFieldsValue({
            name_uz: resp?.data?.["edu-type"]?.name?.uz,
            name_ru: resp?.data?.["edu-type"]?.name?.ru,
            description_uz: resp?.data["edu-type"]?.description?.uz,
            description_ru: resp?.data["edu-type"]?.description?.ru,
            status: parseInt(resp?.data["edu-type"]?.status),
          });
        }
      } catch (error) {
        setLoading(false);
        message.error("Talim turi ma'lumotlarida xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Spin spinning={loader}>
        <Row>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink type="link" to={"/"}>
                  {t("Asosiy")}
                </NavLink>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <NavLink type="link" to={"/education_types"}>
                  {t("Ta'lim shakli")}
                </NavLink>
              </Breadcrumb.Item>

              <Breadcrumb.Item>{t("O'zgartirish")}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <h3>{t("Ta'lim turini o'zgartirish")}</h3>
        </Row>
        <Skeleton loading={loading} active round={true} paragraph={false}>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Row className="add-edu-type" gutter={[16, 16]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="name_uz" label={t("Nomi uz")}>
                  <Input placeholder={t("o'zbekcha nomi")}></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="name_ru" label={t("Nomi ru")}>
                  <Input placeholder={t("ruscha nomi")}></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="description_uz" label={t("Tavsif uz")}>
                  <Input placeholder={t("tavsif")}></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="description_ru" label={t("Tavsif ru")}>
                  <Input placeholder={t("tavsif")}></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  name="status"
                  label={t("Holati")}
                  valuePropName="checked"
                >
                  <Switch
                    unCheckedChildren={t("Faol emas")}
                    checkedChildren={t("Faol")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24} className="text-end">
                <Button className="btn-danger" type="primary">
                  {t("O'chirish")}
                </Button>
                <Button htmlType="submit" className="mx-3" type="primary">
                  {t("Saqlash")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Spin>
    </Fragment>
  );
};

export default EditEduTypes;
