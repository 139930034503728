import { Button, Col, Divider, Empty, message, Row, Skeleton, Tag } from "antd";
import i18next from "i18next";
import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import instance from "../../api/axios";
import { FILE_URL } from "../../api/constants";
import { BiError } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import errorImg from "../../assets/images/undraw_no_data_re_kwbl.svg";
import "./applicant.scss";
import { useTranslation } from "react-i18next";
import {
  certificateIndex,
  otherCertificateIndex,
} from "../../services/api_services/sertificate_api";
import { NavLink, useHistory } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { otherCertificateCategoryIndex } from "../../services/api_services/language_certificate_category_api";

const ApplicantUi = () => {
  // useStates
  const { t } = useTranslation();
  const [applicantInfos, setApplicantInfos] = useState();
  const [applicantFeedback, setApplicantFeedback] = useState([]);
  const [requirements, setRequirements] = useState();
  const [languageCertificates, setLanguageCertificates] = useState([]);
  const [otherCertificateCategories, setOtherCertificateCategories] = useState([]);
  const [totalScore, setTotalScore] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [fileUrls, setFileUrls] = useState({
    img: [],
    passport: [],
    diplom: [],
    sertificate: [],
  });

  // Hujjat statuslari
  const showApplicantStatus = (userStatus) => {
    switch (userStatus) {
      case 0:
        return `${t("yuborildi")}`;
      case 1:
        return `${t("qaytarildi")}`;
      case 2:
        return `${t("o`zgartirildi")}`;
      case 3:
        return `${t("qabul qilindi")}`;
    }
  };

  // Get applicant info
  const getOtherCertificateCategories = () => {
    (async () => {
      const resp = await otherCertificateCategoryIndex();
      setOtherCertificateCategories(resp?.data?.data);
    })();
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        getOtherCertificateCategories();
        const resp = await instance({
          method: "get",
          url: "/application?expand=region,education_language,education_type,universities,faculty,texnikum",
        });
        let data = resp?.data?.data;

        if (resp?.status === 200) {
          setLoading(false);
          setApplicantInfos(resp?.data?.data);
          setApplicantFeedback(resp?.data?.data?.feedbacks);

          let a = 0;
          data?.conversation_balls?.map((e)=>{
            a += +e?.ball;
          })
          setTotalScore((a/data?.conversation_balls?.length).toFixed(2));

          setFileUrls({
            img: [
              {
                uid: "1",
                name:
                  "image" +
                  data?.photo?.substring(
                    data?.photo?.lastIndexOf(".") + 1,
                    data?.photo?.length
                  ),
                ststus: "success",
                url: FILE_URL + data?.folder + "/" + data?.photo,
              },
            ],
            passport: [
              {
                uid: "1",
                name:
                  "image" +
                  data?.passport_copy?.substring(
                    data?.passport_copy?.lastIndexOf(".") + 1,
                    data?.data?.passport_copy?.length
                  ),
                ststus: "success",
                url: FILE_URL + data?.folder + "/" + data?.passport_copy,
              },
            ],
            diplom: [
              {
                uid: "1",
                name:
                  "image" +
                  data?.diploma_copy?.substring(
                    data?.diploma_copy?.lastIndexOf(".") + 1,
                    data?.diploma_copy?.length
                  ),
                ststus: "success",
                url: FILE_URL + data?.folder + "/" + data?.diploma_copy,
              },
            ],
          });
        }
      } catch (e) {
        // message.error("Error get your application");
      }
    })();
  }, []);

  // get applying requirements
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-requirements",
        });
        if (resp?.status === 200) {
          setRequirements(resp?.data?.data);
        }
      } catch (e) {}
    })();
  }, []);

  // get sertificate
  const [otherCertificates, setOtherCertificates] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/admission-requirements",
        });
        if (resp?.status === 200) {
          setRequirements(resp?.data?.data);
        }
        const responseCertificates = await certificateIndex();
        if (responseCertificates) {
          setLanguageCertificates(responseCertificates?.data?.data);
        }
        const otherCertificatesResponse = await otherCertificateIndex();
        setOtherCertificates(otherCertificatesResponse?.data?.data);
      } catch (error) {
        message.error("Ma'lumotlarni ochishda xatolik!");
      }
    })();
  }, []);

  const getPermission = async () => {
    try {
      const resp = await instance({
        url: `https://apiadmission.ftti.uz/api/applications-pdf/${applicantInfos?.id}`,
        method: "get",
      });
      let a = document.createElement("a");
      a.href = `data:application/pdf;base64, ${resp?.data}`;
      console.log(a);
      a.target = "_blank";
      // console.log(resp?.headers['Content-Type']);
      // let typeFile=resp?.headers['Content-Type']?.split("/");
      // console.log(typeFile);
      let fileName = `file.pdf`;
      console.log(fileName);
      a.download = fileName;
      a.click();
      a.remove();
    } catch (error) {}
  };

  const goCertificates = () => {};

  return (
    <Fragment>
      {applicantInfos ? (
        <div className="applicant-ui-wrapper">
          {/* <h3 className="text-center fw-bold">{t("Mening ma`lumotlarim")}</h3> */}
          <Skeleton
            avatar={true}
            paragraph={{
              rows: 10,
            }}
            loading={loading}
            active
            round={true}
          >
            <Row className="shadow-lg rounded px-3">
              <Col
                className="gutter-row personel-infos"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
              >
                <div className="image-wrapper">
                  <img
                    className="img-fluid"
                    src={
                      FILE_URL +
                      applicantInfos?.folder_name +
                      "/" +
                      applicantInfos?.photo
                    }
                    alt=""
                  />
                </div>

                <h3 className="text-center mt-3">{t("Shaxsiy ma'lumotlar")}</h3>

                <div className="personel-text">
                  <p>
                    {t("FIO")}: <span>{applicantInfos?.name}</span>
                    <span>{applicantInfos?.surname}</span>
                    <span>{applicantInfos?.middle_name}</span>
                  </p>
                  <p>
                    {t("Tug'ulgan yili")}:{" "}
                    <span>{applicantInfos?.birthdate}</span>
                  </p>
                  <p>
                    {t("Jinsi")}:{" "}
                    <span>
                      {applicantInfos?.gender === 0
                        ? `${t("Ayol")}`
                        : `${t("Erkak")}`}
                    </span>
                  </p>
                  <p>
                    {t("Viloyat")}: <span>{applicantInfos?.region?.name}</span>
                  </p>
                  {/* <p>
                  {t("Tuman")}: <span>{applicantInfos?.district?.name}</span>
                </p> */}
                  <p>
                    {t("Manzil")}: <span>{applicantInfos?.address}</span>
                  </p>
                  <p>
                    {t("Telefon 1")}: <span>{applicantInfos?.telephone}</span>
                  </p>
                  <p>
                    {t("Telefon 2")}: <span>{applicantInfos?.telephone2}</span>
                  </p>
                </div>

                <h3 className="text-center fs-5 mt-3">{t("Hujjat holati")}</h3>
                <div className="personel-text">
                  <p>
                    {t("Sizning ma'lumotlaringiz")}:
                    <span>{showApplicantStatus(applicantInfos?.status)}</span>
                  </p>
                  <p> Fanlardan o'rtacha bali: {applicantInfos?.subjects_overall_score} </p>
                  { applicantInfos?.is_conversation_ball_visible ? <p> Suhbat o'rtacha bali: {totalScore}</p> : null}
                  <p> Til sertifikati bali: {applicantInfos?.language_certificate_score} </p>
                  <p> Sertfikat bali: {applicantInfos?.other_certificates_score} </p>
                </div>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 16 }}
              >
                <Row>
                  <Col
                    className="passport-infos"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                  >
                    <h3 className="text-center fs-5 mt-3">
                      {t("Pasport ma'lumotlar")}
                    </h3>
                    <div className="passport-text">
                      <p>
                        {t("Pasport seriyasi")}:
                        <span>{applicantInfos?.passport_serie}</span>
                      </p>
                      <p>
                        {t("Pasport raqami")}:
                        <span>{applicantInfos?.passport_numbers}</span>
                      </p>
                      <p>
                        {t("Pasport JSHSHIR")}:
                        <span>{applicantInfos?.passport_jshr}</span>
                      </p>
                    </div>

                    <div className="passport-text">
                      <p>
                        {t("Pasport rangli nusxasi")}:{" "}
                        <a
                          href={
                            FILE_URL +
                            applicantInfos?.folder_name +
                            "/" +
                            applicantInfos?.passport_copy
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("Yuklab olish")}
                        </a>
                      </p>
                    </div>
                  </Col>

                  <Col className="diplom-infos" xs={24} sm={24} md={24} lg={12}>
                    <h3 className="text-center fs-5 mt-3">
                      {t("Diplom ma'lumotlar")}
                    </h3>

                    <div className="diplom-text">
                      <p>
                        {t("Diplom seriyasi")}:{" "}
                        <span>{applicantInfos?.diploma_serie}</span>
                      </p>
                      <p>
                        {t("Diplom raqami")}:{" "}
                        <span>{applicantInfos?.diploma_number}</span>
                      </p>
                      <p>
                        {t("Diplom berilgan sana")}:{" "}
                        <span>{applicantInfos?.diploma_given}</span>
                      </p>
                      <p>
                        {t("Diplom nusxasi")}:{" "}
                        <a
                          href={
                            FILE_URL +
                            applicantInfos?.folder_name +
                            "/" +
                            applicantInfos?.diploma_copy
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {t("Yuklab olish")}
                        </a>
                      </p>
                      {applicantInfos?.qayta_tayyorlov_sertifikat ? (
                        <p>
                          {t("Sertifikat")}:{" "}
                          <a
                            href={
                              FILE_URL +
                              applicantInfos?.folder_name +
                              "/" +
                              applicantInfos?.qayta_tayyorlov_sertifikat
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue"
                          >
                            {t("Yuklab olish")}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col
                    className="education-infos"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                  >
                    <h3 className="text-center fs-5">
                      {t("Ta'lim ma'lumotlar")}
                    </h3>

                    <div className="education-text">
                    <p>
                        {t("Ta'nlangan texnikum")}:
                        <span>
                          {applicantInfos?.texnikum?.name[i18next.language]}
                        </span>
                      </p>
                      <p>
                        {t("Ta`lim yo`nalishi")}:
                        <span>
                          {applicantInfos?.faculty &&
                            applicantInfos?.faculty?.name[i18next.language]}
                        </span>
                      </p>
                      <p>
                        {t("Ta'lim tili")}:
                        <span>
                          {applicantInfos?.education_language &&
                            applicantInfos?.education_language?.name[
                              i18next.language
                            ]}
                        </span>
                      </p>
                      <p>
                        {t("Ta'lim shakli")}:
                        <span>
                          {applicantInfos?.education_type &&
                            applicantInfos?.education_type?.name[
                              i18next.language
                            ]}
                        </span>
                      </p>

                      <p className="mt-3">
                        {t("Ta'nlangan universitetlar")}:
                        <span>
                          {applicantInfos?.universities &&
                            applicantInfos?.universities?.map((item, index) => {
                              return (
                                <span className="d-block d-md-flex">
                                  {" "}
                                  <b>{index + 1}.</b>{" "}
                                  {item?.name[i18next.language]}
                                </span>
                              );
                            })}
                        </span>
                      </p>


                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Col className="personel-infos shadow-lg mt-3 p-3" span={24}>
              <div className="d-flex justify-content-around align-items-center">
                <h3 className=" mt-1">{t("Sertifikatlar")}</h3>
                <h3></h3>
                {/* <Tag className="p-1" color="processing">
                  All certificate score: 32
                </Tag> */}
              </div>

              {languageCertificates ? (
                <div className={"d-block d-md-flex"}>
                  <Col span={24}>
                    <Row>
                      {/* Additional certificates */}
                      <Col xs={24} md={24} lg={12}>
                        <h5 className=" mt-1">
                          {t("Qo`shimcha sertifikatlar")}
                        </h5>
                        <div className="personel-text">
                          {otherCertificateCategories?.length > 0 &&
                            otherCertificateCategories?.map(
                              (element, index) => {
                                return (
                                  <div>
                                    <b>
                                      {index + 1}.
                                      {element?.name[`${i18next.language}`]}
                                    </b>
                                    <div className={"ps-2"}>
                                      {element?.childs?.length > 0 &&
                                        element?.childs?.map(
                                          (elementChild, indexChild) => {
                                            return (
                                              <div>
                                                <div>
                                                  {" "}
                                                  --{" "}
                                                  {
                                                    elementChild?.name[
                                                      `${i18next.language}`
                                                    ]
                                                  }
                                                </div>
                                                <div className={"ps-3"}>
                                                  <b>{t("Fayllar")}: </b>
                                                  {otherCertificates?.length >
                                                    0 &&
                                                    otherCertificates?.map(
                                                      (
                                                        elementCer,
                                                        indexCer
                                                      ) => {
                                                        return (
                                                          <>
                                                            {elementCer?.certificate_category_id ==
                                                              elementChild?.id && (
                                                              <>
                                                                <Tag
                                                                  className="m-1"
                                                                  color="warning"
                                                                  style={{
                                                                    padding:
                                                                      "2px",
                                                                  }}
                                                                >
                                                                  <a
                                                                    target={
                                                                      "_blank"
                                                                    }
                                                                    href={
                                                                      FILE_URL +
                                                                      applicantInfos?.folder_name +
                                                                      "/" +
                                                                      elementCer?.certificate
                                                                    }
                                                                  >
                                                                    {
                                                                      elementCer?.certificate
                                                                    }{" "}
                                                                    <Button
                                                                      size="small"
                                                                      className={
                                                                        "text-secondary"
                                                                      }
                                                                    >
                                                                      <FaEye />{" "}
                                                                    </Button>
                                                                  </a>
                                                                </Tag>
                                                              </>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </Col>

                      {/* Certificates */}
                      <Col xs={24} md={24} lg={12}>
                        <h5 className="mt-1">{t("Chet tili sertifikati")}</h5>
                        <div className="personel-text">
                          <p>
                            {t("Chet tili sertifikati")}:{" "}
                            <span>
                              <a
                                href={
                                  FILE_URL +
                                  applicantInfos?.folder_name +
                                  "/" +
                                  languageCertificates?.certificate
                                }
                              >
                                {t("Yuklab olish")} &nbsp; <BsDownload />{" "}
                              </a>
                            </span>
                          </p>
                          <p>
                            {t("Chet tili sertifikati bali")}:{" "}
                            <span>{languageCertificates?.score}</span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              ) : (
                <div className="text-center">
                  <h4 className={"text-danger text-center "}>
                    Chet tili sertifikatingizni kiritmasangiz hujjatlar qabul
                    qilinmaydi!
                  </h4>
                  <Button
                    type="primary"
                    className="mt-3"
                    onClick={() => history.push(`/applicant/4`)}
                  >
                    {" "}
                    Sertifikat qo'shish{" "}
                  </Button>
                </div>
              )}
            </Col>
          </Skeleton>
        </div>
      ) : (
        <Skeleton
          paragraph={{
            rows: 10,
          }}
          loading={loading}
          active
          round={true}
        >
          <Fragment>
            <div className="no-data-applicantui">
              <h5 className="d-flex justify-content-center">
                {" "}
                {requirements ? requirements[`title_${i18next.language}`] : ""}!
                &nbsp;
                <BiError className="fs-3 d-none d-md-grid text-danger" />
              </h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: requirements
                    ? requirements[`content_${i18next.language}`]
                    : "",
                }}
              ></div>
              <Col span={24} className={"my-3"}>
                <Button type="dashed" danger>
                  <NavLink to={"/applicant/:tab"}>
                    {t("Hujjat topshirish")}&nbsp; +
                  </NavLink>
                </Button>
              </Col>
              {/* <img src={errorImg} alt="network error!" /> */}
            </div>
          </Fragment>
        </Skeleton>
      )}
    </Fragment>
  );
};

export default ApplicantUi;
