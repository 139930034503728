import { Empty } from "antd"

const EmptyData = ( {data, loading}) => {

  return(
     !data?.length && !loading ? <div className="w-100 d-flex justify-content-center py-4">
      <Empty description="Ma'lumot mavjud emas" />
    </div> : null
  )
}

export default EmptyData;