import React, {useState, useEffect} from "react";
import {statisticForCheckerApi} from "../../../services/api_services/statistic_for_checker";
import {Table, Row, Col} from "antd"
import i18next from "i18next";
import {interval_ball} from "../../../services/ball_interval";

const StatisticForChecker = () => {

    const [data, setData] = useState([]);
    const summBalls = (element,ot) => {

        let ball =( parseFloat(interval_ball((parseFloat(element?.student_subject_scores_sum_score) / parseFloat(element?.student_subject_scores_count)).toFixed(2) , parseInt(element?.tex_edu_type)))
            + parseFloat(element?.language_certificate?.language_certificate_score?.ball)
            + ot
            + parseFloat(element?.conversation_balls_sum_ball / parseInt(element?.conversation_balls_count))).toFixed(2);
    return ball
    }
    const columns = [
        {
            title:'#',
            render: (index,element,counter) => <>{counter+1}</>
        },
        {
            title: 'FIO',
            render: (index, element) => <>{element?.surname} {element?.name} {element?.middle_name}</>
        },
         {
            title: 'Texnikum',
            render: (index, element) => <>{element?.texnikum?.name[i18next?.language]}</>
        },
        {
            title: 'Birinchi tanlagan universiteti',
            render: (index, element) => <>{element?.first_university?.university?.name[i18next?.language]}</>
        },
        {
            title: 'Fanlardan o`rtacha bali',
            render: (index, element) => <>{(parseFloat(element?.student_subject_scores_sum_score) / parseFloat(element?.student_subject_scores_count)).toFixed(2)} |
                Natijaviy:{interval_ball((parseFloat(element?.student_subject_scores_sum_score) / parseFloat(element?.student_subject_scores_count)).toFixed(2) , parseInt(element?.tex_edu_type))} </>

        },
        {
            title: 'Chet tili sertifikati',
            render: (index, element) => <>{element?.language_certificate?.language_certificate_score?.score} |
                Natijaviy: {element?.language_certificate?.language_certificate_score?.ball}</>
        },
        {
            title: 'Sertifikatlar bali',
            render: (index, element) => <>{element?.student_other_certificate_scores_sum_score ? element?.student_other_certificate_scores_sum_score : 0}</>
        },
        {
            title: 'Suhbat bali',
            render: (index, element) => <>{element?.conversation_balls_count ? parseFloat(element?.conversation_balls_sum_ball / element?.conversation_balls_count).toFixed(2) : 0}</>
        },
        {
            title:'Umumiy bal',
            render: (index,element) => <>{summBalls(element,element?.student_other_certificate_scores_sum_score ? parseFloat(element?.student_other_certificate_scores_sum_score) : 0)}</>
        }
    ]
    useEffect(() => {
        (async () => {
            const response = await statisticForCheckerApi();
            if (response) {
                setData(response)
            }
        })()
    }, []);

    return (
        <Row>
            <Col xl={24}>
                <Table columns={columns} dataSource={data} pagination={false}/>
            </Col>
        </Row>
    )
}

export default StatisticForChecker;