import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OnlineLessons = () => {
  const auth = useSelector((state) => state.authRedux);
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 16]}>
        {auth?.role?.includes("applier") && (
          <Col xs={24} md={12}>
            <Card
              bordered={true}
              style={{
                width: 100 + "%",
                borderRadius: "10px",
              }}
              cover={
                <iframe
                  width="1280"
                  height="480"
                  src="https://www.youtube.com/embed/q-S2sWj-E4w"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              }
            >
              <Meta
                title={t("Hujjat topshirish")}
                description="http://yurtex.tsul.uz"
              />
            </Card>
          </Col>
        )}

        {auth?.role?.includes("texnikum-admin") && (
          <Col xs={24} md={12}>
            <Card
              bordered={true}
              style={{
                width: 100 + "%",
                borderRadius: "10px",
              }}
              cover={
                <iframe
                  width="1280"
                  height="480"
                  src="https://www.youtube.com/embed/q-S2sWj-E4w"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              }
            >
              <Meta
                title={t("Hujjat topshirish")}
                description="http://yurtex.tsul.uz"
              />
            </Card>
          </Col>
        )}

        {auth?.role?.includes("texnikum-admin") && (
          <Col xs={24} md={12}>
            <Card
              bordered={true}
              style={{
                width: 100 + "%",
                borderRadius: "10px",
              }}
              cover={
                <iframe
                  width="1280"
                  height="480"
                  src="https://www.youtube.com/embed/xBKHFbriHB8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              }
            >
              <Meta
                title={t("Tex admin hujjat tekshirish")}
                description="http://yurtex.tsul.uz"
              />
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default OnlineLessons;
