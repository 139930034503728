import React from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import { Fragment } from "react";
import { useEffect } from "react";
import instance from "../../../api/axios";
import { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpdateTexnikum = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const path = useLocation().pathname;
  const pathParts = path?.split("/");
  const { t } = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };
    try {
      setLoader(true);
      const resp = await instance({
        method: "put",
        url: `/texnikum/${pathParts[pathParts.length - 1]}`,
        data: values,
      });
      history.push("/texnikumlar");
    } catch (error) {
      message.error("Ma'lumotni o'zgartirishda xatolik!");
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/texnikum/${pathParts[pathParts?.length - 1]}`,
        });
        if (resp.status === 200) {
          setLoading(false);
          form.setFieldsValue({
            name_uz: resp?.data?.data?.name?.uz,
            name_ru: resp?.data?.data?.name?.ru,
            description_uz: resp?.data?.data?.description?.uz,
            description_ru: resp?.data?.data?.description?.ru,
            is_active: parseInt(resp.data?.data?.is_active) === 1,
          });
        }
      } catch (error) {
        setLoading(true);
        message.error("Ma'lumotlarni ochishda xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink type="link" to={"/"}>
                  {t("Asosiy")}
                </NavLink>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <NavLink type="link" to={"/texnikumlar"}>
                  {t("Texnikumlar")}
                </NavLink>
              </Breadcrumb.Item>

              <Breadcrumb.Item>{t("O'zgartirish")}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Col>
      </Row>

      <Spin spinning={loader}>
        <div>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3>{t("Ma'lumotlarni o'zgartirish")}</h3>

            <Skeleton loading={loading} active round={true} title={false}>
              <Row gutter={[16, 16]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item name="name_uz" label={t("Nomi uz")}>
                    <Input placeholder={t("nomi")}></Input>
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item name="name_ru" label={t("Nomi ru")}>
                    <Input placeholder="nomi"></Input>
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item name="description_uz" label={t("Tavsif uz")}>
                    <Input placeholder="tavsif"></Input>
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item name="description_ru" label={t("Tavsif ru")}>
                    <Input placeholder="tavsif"></Input>
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="is_active"
                    label={t("Holati")}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={t("Faol holat")}
                      unCheckedChildren={t("Faol emas")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Skeleton>

            <Row gutter={[16, 16]} className="my-3">
              <Col span={24} className="text-end">
                <Button htmlType="reset" className="btn-danger" type="primary">
                  {t("O'chirish")}
                </Button>
                <Button htmlType="submit" className="mx-3" type="primary">
                  {t("Saqlash")}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
};

export default UpdateTexnikum;
