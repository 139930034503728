/** @format */

import {
  Badge,
  Breadcrumb,
  Col,
  DatePicker,
  Divider,
  Input,
  message,
  Pagination,
  Row,
  Skeleton,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import instance from "../../api/axios";
import EmptyData from "../../components/emptyData";
import LineLoading from "../../components/lineLoading";
import useDebounce from "../../components/useDebounce";
import LoadExcelData from "../../services/download_excel";
import {
  allConversationBallsSum,
  getLanguageCertificateScore,
  interval_ball,
} from "../../services/ball_interval";

const { TabPane } = Tabs;
const { Search } = Input;

const Checker = () => {
  // states
  const auth = useSelector((state) => state.authRedux?.auth);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  // const [search, setSearch] = useState("");
  const [status, setStatus] = useState("0");
  const [isActive, setIsActive] = useState(auth?.admission_active);
  const [tabs, setTabs] = useState([
    {
      name: "Hammasi",
      count: 0,
    },
    {
      name: "Topshirilgan",
      count: 0,
    },
    {
      name: "Qaytarilgan",
      count: 0,
    },
    {
      name: "O'zgartirilgan",
      count: 0,
    },
    {
      name: "Qabul qilingan",
      count: 0,
    },
    {
      name: "Tasdiqlanmagan",
      count: 0,
    },
  ]);

  // const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    (async () => {
      try {
        const response = await instance({
          method: "get",
          url: "/checker/badge/reports",
        });
        tabs.map((tab, i) => {
          tab.count = response.data.data[i];
        });
        setTabs(tabs);
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/applications?expand=texnikum,faculty,universities${
            Number(status) ? `&status=${status - 1}` : ""
          }`,
          params: { "per-page": perPage, page: currentPage },
        });
        setStudents(resp?.data?.data?.data);
        setCurrentPage(resp?.data?.data?.current_page);
        setPerPage(resp?.data?.data?.per_page);
        setTotal(resp?.data?.data?.total);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error(t("Malumot olishda xatolik!"));
      }
    })();
  }, [i18n.language, status, currentPage, perPage]);

  // useEffect(() => {
  //   setStatus(sessionStorage.getItem("checker_tab_status") ? sessionStorage.getItem("checker_tab_status") : "0");
  // },[]);

  const changeStatus = (status) => {
    sessionStorage.setItem("checker_tab_status", status);
  };

  const admissionActive = async (e) => {
    setIsActive(e);
    const data = new FormData();
    data.append("status", e ? 1 : 0);

    const resp = await instance({
      method: "post",
      url: "/admission-settings/status-change",
      data,
    });
  };

  return (
    <div>
      <Col span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink type="link" to={"/"}>
              {t("Asosiy")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Hujjatlar hisoboti")}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <div className="data-picker-wrapper d-flex align-items-center justify-content-between">
        <h5 className="me-4 mt-3">{t("Hujjatlar hisoboti")}</h5>
        <div className="d-flex justify-content-center align-items-center">
          <strong className="me-3">{t("Hujjat topshirish holati")}: </strong>
          <Switch
            checkedChildren={t("yoqish")}
            unCheckedChildren={t("o'chirish")}
            checked={isActive}
            onChange={(e) => admissionActive(e)}
          />
        </div>
        {/* <div>
        <DatePicker
          showToday={false}
          // format={formatDate}
          // onChange={(e, d) => setFrom(d)}
          renderExtraFooter={() => (
            <div className="text-center text-blue fw-bold">
              "Boshlanish sanasi"
            </div>
          )}
        />
        <span> &nbsp;{t("dan")}&nbsp; </span>
        <DatePicker
          showToday={false}
          // format={formatDate}
          // onChange={(e, d) => setTo(d)}
          renderExtraFooter={() => (
            <div className="text-center text-blue fw-bold">
              "Tugash sanasi"
            </div>
          )}
        />
        <span>&nbsp; {t("gacha")}&nbsp; </span>
        <LoadExcelData
        // from={from}
        // to={to}
        // addmission_id={activeTabKey}
        // status={statusTabActiveKey}
        />
      </div> */}
      </div>

      {/* <h5 className='mb-3'>{t("Talabalar bazasi")}</h5> */}
      <Divider className="my-2 mb-3" />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {/* <div className='text-end'>
          <Search
            value={search}
            allowClear
            placeholder='F.I.O. va pasport seriya raqam orqali qidirish ... '
            style={{ width: 400 }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div> */}
          <Tabs
            activeKey={status}
            onChange={(e) => {
              setStatus(e);
              setCurrentPage(1);
              changeStatus(e);
            }}
          >
            {tabs.map((tab, index) => (
              <TabPane
                tab={
                  <Badge color="blue" offset={[10, 0]} count={tab?.count}>
                    {t(tab?.name)}
                  </Badge>
                }
                key={index}
              >
                {/* <LineLoading loading={loading} /> */}
                {loading ? (
                  <Skeleton active />
                ) : (
                  <Table className="table table-responsive table-bordered table-striped table-hover">
                    <Thead>
                      <Tr>
                        <Th>№</Th>
                        <Th>{t("FIO")}</Th>
                        {/*<Th>{t("Pasport seriya va raqam")}</Th>*/}
                        <Th>{t("Telefon raqam")}</Th>
                        <Th>{t("Status")}</Th>
                        <Th>{t("Ta`lim shakli (Texnikum)")}</Th>
                        <Th>{t("Fanlardan bali")}</Th>
                        <Th>{t("Til sertfikat bali")}</Th>
                        <Th>{t("Sertfikat bali")}</Th>
                        <Th>{t("Suhbat bali")}</Th>
                        <Th>{t("Umumiy ball")}</Th>
                        <Th>{t("Amallar")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {students?.length
                        ? students?.map((item, i) => (
                            <Tr key={i}>
                              <Td style={{ width: 40 }}>
                                {perPage * (currentPage - 1) + i + 1}
                              </Td>
                              <Td>
                                <NavLink
                                  to={`/applicant_viewer/${item?.application?.id}`}
                                  type="link"
                                  className="name_link"
                                >
                                  {item?.application?.name}{" "}
                                  {item?.application?.surname}{" "}
                                  {item?.application?.middle_name}
                                </NavLink>
                              </Td>
                              {/*<Td>*/}
                              {/*  {item?.application?.passport_serie}{" "}*/}
                              {/*  {item?.application?.passport_numbers}*/}
                              {/*</Td>*/}
                              <Td>{item?.telephone}</Td>
                              <Td>
                                <div>
                                  {item?.application?.status === 0 ? (
                                    <Tag color="blue">{t("Topshirilgan")}</Tag>
                                  ) : item?.application?.status === 1 ? (
                                    <Tag color="red">{t("Qaytarilgan")}</Tag>
                                  ) : item?.application?.status === 2 ? (
                                    <Tag color="orange">
                                      {t("O'zgartirilgan")}
                                    </Tag>
                                  ) : item?.application?.status === 3 ? (
                                    <Tag color="green">
                                      {t("Qabul qilingan")}
                                    </Tag>
                                  ) : item?.application?.status === 4 ? (
                                    <Tag color="red">{t("Tasdiqlanmagan")}</Tag>
                                  ) : null}
                                  {item?.tex_status?.status === 3 ? (
                                    <Tag color="green">{t("Tasdiqlangan")}</Tag>
                                  ) : item?.tex_status?.status === 2 ? (
                                    <Tag color="red">{t("Tasdiqlanmagan")}</Tag>
                                  ) : null}
                                </div>
                              </Td>
                              <Td>
                                {item?.application?.tex_edu_type
                                  ? "Sirtqi"
                                  : "Kunduzgi"}
                              </Td>
                              <Td>
                                {item?.subjects_overall_score} | Natijaviy:{" "}
                                {interval_ball(
                                  item?.subjects_overall_score,
                                  parseInt(item?.application?.tex_edu_type)
                                )}
                              </Td>
                              <Td>{item?.language_certificate_score?.ball}</Td>
                              <Td>{item?.certificate_overall_score}</Td>
                              <Td>
                                {allConversationBallsSum(item?.application)}
                              </Td>
                              <Td>
                                {(
                                  parseFloat(
                                    allConversationBallsSum(item?.application)
                                  ) +
                                  parseFloat(item?.certificate_overall_score) +
                                  parseFloat(
                                    item?.language_certificate_score?.ball
                                  ) +
                                  parseFloat(
                                    interval_ball(
                                      item?.subjects_overall_score,
                                      parseInt(item?.application?.tex_edu_type)
                                    )
                                  )
                                ).toFixed(2)}
                              </Td>
                              <Td className="text-center" style={{ width: 60 }}>
                                <NavLink
                                  to={`/applicant_viewer/${item?.application?.id}`}
                                  type="link"
                                >
                                  <FaEye size={16} className="my-1 mb-2" />
                                </NavLink>
                              </Td>
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                )}
                {total ? <Tag>Jami: {total} ta</Tag> : null}
                <EmptyData data={students} loading={loading} />
                {students?.length ? (
                  <div className="text-end">
                    <Pagination
                      defaultCurrent={1}
                      current={currentPage}
                      pageSize={perPage}
                      total={total}
                      onChange={(e) => setCurrentPage(e)}
                      onShowSizeChange={(e) => setPerPage(e)}
                    />
                  </div>
                ) : null}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Checker;
