import React from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
} from "antd";
import { Fragment } from "react";
import { useEffect } from "react";
import instance from "../../../api/axios";
import { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const { Option } = Select;

const EditFaculty = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [eduTypes, setEduTypes] = useState([]);
  const [eduLangs, setEduLangs] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = useLocation().pathname;
  let a = url.split("/");
  const [faculty, setFaculty] = useState([]);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };
    try {
      const resp = await instance({
        method: "put",
        url: `/faculties/${a[a.length - 1]}`,
        data: values,
      });
      history.push("/faculty");
    } catch (error) {
      message.error("Error in editing faculty");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/faculties/${
            a[a.length - 1]
          }?expand=education_languages, education_types`,
        });
        if (resp.status === 200) {
          setLoading(false);
          let a = resp?.data?.faculty?.education_languages?.map(
            (item) => `${item?.id}`
          );
          let b = resp?.data?.faculty?.education_types?.map(
            (item) => `${item?.id}`
          );
          form.setFieldsValue({
            status: parseInt(resp.data?.faculty?.status) === 1,
            name_uz: resp?.data?.faculty?.name?.uz,
            name_ru: resp?.data?.faculty?.name?.ru,
            description_uz: resp?.data?.faculty?.description?.uz,
            description_ru: resp?.data?.faculty?.description?.ru,
            edu_langs: a,
            edu_types: b,
          });
        }
      } catch (error) {
        setLoading(false);
        message.error("Fakultet ma'lumotlarini ochishda xatolik!");
      }
    })();
  }, []);

  // get edu types
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/edu-types",
        });
        if (resp?.status === 200) {
          setEduTypes(resp?.data?.edu_types);
        }
      } catch (error) {
        message.error("Error read education types!");
      }
    })();
  }, []);

  // get edu langs
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/edu-langs",
        });
        if (resp?.status === 200) {
          setEduLangs(resp?.data?.edu_langs);
        }
      } catch (error) {
        message.error("Error read education langs!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <NavLink type="link" to={"/faculty"}>
                {t("Fakultetlar")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("O'zgartirish")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Skeleton loading={loading} active round={true} paragraph={false}>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Row className="add-edu-type" gutter={[16, 16]}>
            <Col span={24}>
              <h3>{t("Ta`lim yo`nalishi o'zgartirish")}</h3>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="name_uz" label={t("Nomi uz")}>
                <Input placeholder={t("nomi uz")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="name_ru" label={t("Nomi ru")}>
                <Input placeholder="nomi ru"></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="description_uz" label={t("Tavsif uz")}>
                <Input placeholder="tavsif"></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="description_ru" label={t("Tavsif ru")}>
                <Input placeholder="tavsif"></Input>
              </Form.Item>
            </Col>

            {/* education items */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Please input field value!" },
                ]}
                name={"edu_types"}
                label={t("Ta'lim shakli")}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder={t("ta'lim turi")}
                  allowClear
                >
                  {eduTypes?.map((item, i) => (
                    <Select.Option key={i} value={"" + item?.id}>
                      {item?.name[[i18next.language]]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Please input field value!" },
                ]}
                name={"edu_langs"}
                label={t("Ta'lim tili")}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="ta'lim tili"
                  allowClear
                >
                  {eduLangs?.map((item, i) => (
                    <Select.Option key={i} value={"" + item?.id}>
                      {item?.name[[i18next.language]]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                name="status"
                label={t("Holati")}
                valuePropName="checked"
              >
                <Switch
                  unCheckedChildren={t("Faol emas")}
                  checkedChildren={t("Faol")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="my-3">
            <Col span={24} className="text-end">
              <Button htmlType="reset" className="btn-danger" type="primary">
                {t("O'chirish")}
              </Button>
              <Button htmlType="submit" className="mx-3" type="primary">
                {t("Saqlash")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Fragment>
  );
};

export default EditFaculty;
