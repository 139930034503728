/** @format */

import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Upload,
  Form,
  Select,
  message,
  Modal,
  Tag,
  Popconfirm,
  DatePicker,
  notification,
  Spin,
} from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { BsDownload, BsPlus, BsTrash, BsUpload } from "react-icons/bs";
import {
  languageCertificateCategoryIndex,
  otherCertificateCategoryIndex,
} from "../../services/api_services/language_certificate_category_api";
import {
  certificateIndex,
  certificateStore,
  certificateUpdate,
  otherCertificateDelete,
  otherCertificateIndex,
  otherCertificateStore,
} from "../../services/api_services/sertificate_api";
import { FILE_URL } from "../../api/constants";
import moment from "moment";
import { applicantIndex } from "../../services/api_services/applicant_api";

const AllCertificatesCrudTab = (props) => {
  const {
    languageCertificateForm,
    otherCertificateForm,
    reloadCertificateTab,
    clickCertificateSaveButton,
  } = props;
  const [languageCertificate, setLanguageCertificate] = useState();
  const [applicantInfos, setApplicantInfos] = useState(undefined);
  const [languageCertificateCategories, setLanguageCertificateCategories] =
    useState([]);
  const [otherCertificateCategories, setOtherCertificateCategories] = useState(
    []
  );
  const [otherCertificates, setOtherCertificates] = useState([]);
  const { t } = useTranslation();
  const { Option } = Select;
  const [
    languageCertificateUploadFileList,
    setLanguageCertificateUploadFileList,
  ] = useState([]);
  const [otherCertificateUploadFileList, setOtherCertificateUploadFileList] =
    useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [certificate, setSertificate] = useState(undefined);
  const onFinishLanguageCertificateStore = (values) => {
    (async () => {
      setIsLoading(true);
      const data = new FormData();
      data.append(
        "language_certificate_category_id",
        values?.language_certificate_category_id
      );
      data.append(
        "language_certificate_score_id",
        values?.language_certificate_score_id
      );
      data.append(
        "expires_at",
        moment(values?.expires_at).format("YYYY-MM-DD")
      );
      data.append("score", values?.score);
      if (values?.certificate?.file?.originFileObj) {
        data.append("certificate", values?.certificate?.file?.originFileObj);
      }
      if (languageCertificate?.id) {
        const storeLangCertResp = await certificateUpdate(
          data,
          languageCertificate?.id
        );
        if (storeLangCertResp?.status === 200) {
          message.success(`${t("Chet tili sertifikati o`zgartirildi")}`);
          setReload(!reload);
        }
      } else {
        const storeLangCertResp = await certificateStore(data);
        if (storeLangCertResp?.status === 200) {
          message.success(t("Chet tili sertifikati saqlandi"));
          setReload(!reload);
        } else if (storeLangCertResp?.response?.status === 422) {
          languageCertificateForm.setFields([
            {
              name: "certificate",
              errors: storeLangCertResp?.response?.data?.errors?.certificate,
            },
          ]);
        }
      }
      setIsLoading(false);
      window.scrollTo(0, 0);
    })();
  };
  const onFinishOtherCertificateStore = (values) => {
    (async () => {
      const data = new FormData();
      data.append("certificate_category_id", values?.category_id);
      if (values?.certificate?.file?.originFileObj) {
        data.append("certificate", values?.certificate?.file?.originFileObj);
      }
      const storeLangCertResp = await otherCertificateStore(data);

      if (storeLangCertResp?.status === 200) {
        message.success(t("Sertifikat saqlandi"));
        setReload(!reload);
        handleCancel();
      } else if (storeLangCertResp?.response?.status === 422) {
        otherCertificateForm.setFields([
          {
            name: "certificate",
            errors: storeLangCertResp?.response?.data?.errors?.certificate,
          },
        ]);
      }
    })();
  };
  const languageUploadConfig = {
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };

  useEffect(() => {
    if (applicantInfos) {
      getLanguageCertificate();
    }
  }, [applicantInfos]);

  const customUploadRequest = () => {};
  useEffect(() => {
    (async () => {
      getApplicantInfo();
      setIsLoading(true);
      getLanguageCertificateCategories();
      getOtherCertificateCategory();
      getOtherCertificates();
      setIsLoading(false);
    })();
  }, [reload]);
  const getApplicantInfo = () => {
    (async () => {
      setIsLoading(true);
      const applicantResponse = await applicantIndex();
      if (applicantResponse?.status == 200) {
        setApplicantInfos(applicantResponse?.data?.data);
        if (applicantResponse?.data?.data) {
          if (
            applicantResponse?.data?.data?.status == 0 ||
            applicantResponse?.data?.data?.status == 1 ||
            applicantResponse?.data?.data?.status == 2
          ) {
            setIsDisabled(false);
          } else {
            setIsDisabled(true);
          }
        } else {
          setIsDisabled(true);
        }
      }
      setIsLoading(false);
    })();
  };
  console.log("other", otherCertificateCategories);
  const getLanguageCertificateCategories = (infos) => {
    (async () => {
      setIsLoading(true);
      const responseLangCategory = await languageCertificateCategoryIndex();
      if (responseLangCategory?.status === 200) {
        setLanguageCertificateCategories(responseLangCategory?.data?.data);
      }
      setIsLoading(false);
    })();
  };
  const getOtherCertificateCategory = () => {
    (async () => {
      setIsLoading(true);
      const respOtherCertificateCategories =
        await otherCertificateCategoryIndex();
      if (respOtherCertificateCategories?.status === 200) {
        setOtherCertificateCategories(
          respOtherCertificateCategories?.data?.data
        );
      }
      setIsLoading(false);
    })();
  };

  const getLanguageCertificate = () => {
    (async () => {
      setIsLoading(true);
      const responseLangCert = await certificateIndex();
      if (responseLangCert?.status === 200) {
        languageCertificateForm?.setFieldsValue({
          language_certificate_score_id:
            responseLangCert?.data?.data?.language_certificate_score_id,
          language_certificate_category_id:
            responseLangCert?.data?.data?.language_certificate_category_id,
          score: responseLangCert?.data?.data?.score,
          expires_at: responseLangCert?.data?.data?.expires_at
            ? moment(responseLangCert?.data?.data?.expires_at)
            : null,
          certificate: [
            {
              uid: responseLangCert?.data?.data?.id,
              name: responseLangCert?.data?.data?.certificate,
              status: "success",
              url:
                FILE_URL +
                applicantInfos?.folder_name +
                "/" +
                responseLangCert?.data?.data?.certificate,
            },
          ],
        });
        setLanguageCertificateUploadFileList([
          {
            uid: responseLangCert?.data?.data?.id,
            name: responseLangCert?.data?.data?.certificate,
            status: "success",
            url:
              FILE_URL +
              applicantInfos?.folder_name +
              "/" +
              responseLangCert?.data?.data?.certificate,
          },
        ]);
        setLanguageCertificate(responseLangCert?.data?.data);
      }
      setIsLoading(false);
    })();
  };
  const getOtherCertificates = () => {
    (async () => {
      setIsLoading(true);
      const respOtherCertificates = await otherCertificateIndex();
      if (respOtherCertificates?.status === 200) {
        setOtherCertificates(respOtherCertificates?.data?.data);
      }
      setIsLoading(false);
    })();
  };

  // function getScoreDescription(score) {
  //   let string = "";
  //   for (const stringKey in score?.description) {
  //     string += stringKey + " ";
  //     string +=
  //       score?.description[stringKey][0] +
  //       " - " +
  //       score?.description[stringKey][1] +
  //       " | ";
  //   }
  //   return string;
  // }

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    otherCertificateForm.submit();
  };
  const handleCancel = () => {
    otherCertificateForm.resetFields();
    setIsModalVisible(false);
  };
  const deleteCertificate = (elementId) => {
    (async () => {
      const deleteResponse = await otherCertificateDelete(elementId);
      if (deleteResponse?.status === 200) {
        message.success(t("Sertifikat o`chirildi"));
        setReload(!reload);
      }
    })();
  };
  const openNotification = (message) => {
    notification.info({
      message,
      description: "",
      placement: "right",
    });
  };
  return (
    <>
      <Spin spinning={isLoading} tip="Loading...">
        <h5>{t("Chet tili sertifikati")}</h5>
        <Form
          scrollToFirstError={true}
          name={"languageCertificateForm"}
          form={languageCertificateForm}
          labelCol={{
            span: 23,
          }}
          wrapperCol={{
            span: 23,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishLanguageCertificateStore}
          autoComplete={"on "}
          className={"w-100 "}
        >
          <Row className={"border-md p-1 "}>
            <Col xl={8} lg={16} md={20} sm={24} xs={24}>
              <Form.Item
                label={t("Chet tili sertifikat turi")}
                name={"language_certificate_category_id"}
                rules={[
                  {
                    required: true,
                    message: t("Chet tili sertifikat turini tanlang"),
                  },
                ]}
              >
                <Select
                  // disabled={isDisabled || languageCertificate?.is_verified}
                  style={{ width: "100%" }}
                  showSearch
                  placeholder={t("Sertifikat turini tanlang")}
                  optionFilterProp={"children"}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase())
                  }
                >
                  {languageCertificateCategories?.languageCertificateCategories?.map(
                    (element, index) => {
                      return (
                        <Option key={index} value={element?.id}>
                          {element?.name}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} lg={16} md={20} sm={24} xs={24}>
              <Form.Item
                label={t("Chet tili sertifikat darajasi")}
                name={"language_certificate_score_id"}
                rules={[
                  {
                    required: true,
                    message: t("Chet tili sertifikat darajasini tanlang"),
                  },
                ]}
              >
                <Select
                  // disabled={isDisabled || languageCertificate?.is_verified}
                  style={{ width: "100%" }}
                  showSearch
                  placeholder={t("Sertifikat darajasini tanlang")}
                  optionFilterProp={"children"}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {languageCertificateCategories?.languageCertificateScores?.map(
                    (element, index) => {
                      return (
                        <Option key={index} value={element?.id}>
                          <b>{element?.score}</b>
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} lg={16} md={20} sm={24} xs={24}>
              <Form.Item
                label={t("Chet tili sertifikat amal qilish muddati")}
                name={"expires_at"}
                rules={[
                  {
                    required: true,
                    message: t(
                      "Chet tili sertifikat amal qilish muddatini kiriting"
                    ),
                  },
                ]}
              >
                <DatePicker
                // disabled={isDisabled || languageCertificate?.is_verified}
                />
              </Form.Item>
            </Col>
            <Col xl={8} lg={16} md={20} sm={24} xs={24}>
              <Form.Item
                label={t("Ball")}
                name={"score"}
                rules={[
                  {
                    required: true,
                    message: t("Chet tili sertifikat balini kiriting"),
                  },
                ]}
              >
                <Input
                // disabled={isDisabled || languageCertificate?.is_verified}
                />
              </Form.Item>
            </Col>
            <Col xl={8} lg={16} md={20} sm={24} xs={24}>
              <Form.Item
                label={t("Chet tili sertifikati faylini tanlang") + " (PDF)"}
                name={"certificate"}
                rules={[
                  {
                    required: true,
                    message: t("Chet tili sertifikat darajasini tanlang"),
                  },
                ]}
              >
                <Upload
                  // disabled={isDisabled || languageCertificate?.is_verified}
                  {...languageUploadConfig}
                  accept={".pdf"}
                  fileList={languageCertificateUploadFileList}
                  customRequest={({ onSuccess }) => {
                    onSuccess("success");
                  }}
                  onChange={(e) => {
                    setLanguageCertificateUploadFileList([
                      {
                        uid: "-1",
                        name: e?.file?.name,
                        status: "success",
                        url: window.URL.createObjectURL(e?.file?.originFileObj),
                      },
                    ]);
                  }}
                >
                  <Button>
                    <span>{t("Yuklash")}</span>
                    <span className="mx-1">
                      <BsUpload />
                    </span>
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row className={"d-flex justify-content-end"}>
            <Button
              // disabled={isDisabled}
              className={"btn-primary"}
              onClick={clickCertificateSaveButton}
            >
              {t("Sertifikatlarni saqlash")}
            </Button>
          </Row>
        </Form>

        <div
          className={
            "d-flex justify-content-between  align-items-center mt-3 mb-3"
          }
        >
          <h5 className={"me-2"}>{t("Qo`shimcha sertifikatlar")}</h5>{" "}
          <Button
            size="small"
            // disabled={isDisabled}
            onClick={showModal}
            type={"primary"}
          >
            {t("Sertifikat qo`shish")}&nbsp;+
          </Button>
        </div>

        <Row className={" p-1 "}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            {otherCertificateCategories?.length > 0 &&
              otherCertificateCategories?.map((element, index) => {
                return (
                  <div>
                    <b>
                      {index + 1}.{element?.name[`${i18next.language}`]}
                    </b>
                    <div className={"ps-2"}>
                      {element?.childs?.length > 0 &&
                        element?.childs?.map((elementChild, indexChild) => {
                          return (
                            <div>
                              <div>
                                {" "}
                                -- {elementChild?.name[`${i18next.language}`]}
                              </div>
                              <div className={"ps-3"}>
                                <b>{t("Fayllar")}:</b>
                                {otherCertificates?.length > 0 &&
                                  otherCertificates?.map(
                                    (elementCer, indexCer) => {
                                      return (
                                        <>
                                          {elementCer?.certificate_category_id ==
                                            elementChild?.id && (
                                            <>
                                              <Tag
                                                className="mx-1 px-1"
                                                color="warning"
                                              >
                                                {elementCer?.certificate}
                                                <Button
                                                  size="small"
                                                  className={
                                                    "text-success mx-1 px-0"
                                                  }
                                                >
                                                  <a
                                                    target={"_blank"}
                                                    href={
                                                      FILE_URL +
                                                      applicantInfos?.folder_name +
                                                      "/" +
                                                      elementCer?.certificate
                                                    }
                                                  >
                                                    <BsDownload className="mx-2" />{" "}
                                                  </a>
                                                </Button>
                                                {!isDisabled &&
                                                  !elementCer?.is_verified && (
                                                    <Popconfirm
                                                      title={t("O`chirasizmi")}
                                                      onConfirm={() =>
                                                        deleteCertificate(
                                                          elementCer?.id
                                                        )
                                                      }
                                                      okText={t("O`chirish")}
                                                      cancelText={t(
                                                        "Bekor qilish"
                                                      )}
                                                    >
                                                      <Button
                                                        size="small"
                                                        className={
                                                          "text-danger m-1"
                                                        }
                                                      >
                                                        {" "}
                                                        <BsTrash />{" "}
                                                      </Button>
                                                    </Popconfirm>
                                                  )}
                                              </Tag>
                                            </>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </Col>
        </Row>
      </Spin>
      {!isDisabled && (
        <Modal
          title={t("Sertifikat kiritish")}
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={t("Saqlash")}
          cancelText={t("Orqaga")}
        >
          <Row className={"d-flex  w-100 justify-content-center"}>
            <Form
              scrollToFirstError={true}
              name="basic"
              form={otherCertificateForm}
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishOtherCertificateStore}
              autoComplete="off"
              className={"w-100"}
            >
              <Form.Item
                label={t("Sertifikat turi")}
                name={"category_id"}
                rules={[
                  {
                    required: true,
                    message: t("Chet tili sertifikat turini tanlang"),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder={t("Sertifikat turini tanlang")}
                  optionFilterProp={"children"}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {otherCertificateCategories?.length > 0 &&
                    otherCertificateCategories?.map(
                      (element, index) =>
                        element?.childs?.length > 0 &&
                        element?.childs?.map((elemenChild, indexChild) => {
                          return (
                            <Option key={indexChild} value={elemenChild?.id}>
                              {elemenChild?.name[`${i18next.language}`]}
                            </Option>
                          );
                        })
                    )}
                </Select>
              </Form.Item>

              <Form.Item
                label={t("Sertifikat fayli")}
                name="certificate"
                rules={[
                  {
                    required: true,
                    message: "Sertifikat nomini kiriting!",
                  },
                ]}
              >
                <Upload
                  accept={".pdf"}
                  {...languageUploadConfig}
                  customRequest={({ onSuccess }) => {
                    onSuccess("success");
                  }}
                  onChange={(e) => {
                    setOtherCertificateUploadFileList([
                      {
                        uid: "-1",
                        name: e?.file?.name,
                        status: "success",
                        url: window.URL.createObjectURL(e?.file?.originFileObj),
                      },
                    ]);
                  }}
                >
                  <Button className={"w-100"}>
                    {t("Yuklash")}{" "}
                    <span className="mx-1">
                      <BsUpload />
                    </span>
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      )}
    </>
  );
};
export default AllCertificatesCrudTab;
