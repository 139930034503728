/** @format */

import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  InputNumber,
  List,
  message,
  notification,
  Row,
  Spin,
  Tag,
} from "antd";
import i18next from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsDot } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import instance from "../../../api/axios";
import { FILE_URL } from "../../../api/constants";

const SertficateTab = ({ _id,general }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [srefresh, setSrefresh] = useState(false);
  const [lrefresh, setLrefresh] = useState(false);
  const [certificateCategory, setCertificateCategory] = useState([]);
  const [langScore, setLangScore] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/texnikum-admin/student/details?user_id=${_id}&expand=certificates.category.parent,languageCertificate.languageCertificateScore,languageCertificate.languageCertificateCategory,application,scores`,
        });
        setData(resp?.data?.data);
        setLangScore(resp?.data?.data?.user?.language_certificate_score?.score);

        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error("Malumot olishda xatolik!");
      }
    })();
  }, [lrefresh]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/certificate-categories?user_id=${_id}`,
        });
        setCertificateCategory(resp?.data?.data);

        let subScore = {};
        resp.data?.data?.forEach((e) => {
          e?.childs?.forEach((a) => {
            subScore[a?.id] = a?.scores[0]?.score;
          });
        });
        form.setFieldsValue({
          ...subScore,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Malumot olishda xatolik!");
      }
    })();
  }, [srefresh]);

  const onCertificate = async (values) => {
    setLoading(true);

    let sub_data = { user_id: Number(_id), scores: [] };
    let a = 0;
    let b = 0;

    Object.entries(values).forEach(([key, value]) => {
      if (Number(key) <= 7) a += Number(value);
      else b += Number(value);

      sub_data.scores.push({
        [`category_id`]: Number(key),
        [`score`]: value,
      });
    });

    // if (a > 30 || b > 10) {
    //   message.error(t("Birinchi kategoriya uchun bal 30dan ikkinchisi uchun 10dan oshmasligi kerak!"));
    // } else {
      const res = await instance({
        method: "post",
        url: "/checker/certificate/give-score",
        data: sub_data,
      });
      if (res.data.status === "Success") {
        setSrefresh(!srefresh);
        notification.success({
          message: t("Malumot saqlandi!"),
        });
      } else {
        message.error(t("Malumot saqlashda xatolik!"));
      }
    // }
    setLoading(false);
  };

  const onLangCertificate = async () => {
      const dataSend = {
        [`user_id`]: Number(_id),
        [`score`]: Number(data?.user?.language_certificate?.language_certificate_score?.ball),
      };
      await instance({
        method: "post",
        url: "/checker/language-certificate/give-score",
        data:dataSend,
      })
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            setLrefresh(!lrefresh);
            notification.success({
              message: t("Malumot saqlandi!"),
            });
          } else {
            message.error(t("Malumot saqlashda xatolik!"));
          }
        })
        .catch((error) => {
          message.error(t("Malumot saqlashda xatolik!"));
        });
  };

  return (
    <div>
      <Spin tip='Loading...' spinning={loading}>
        <Card className='car'>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <h5>{t("Chet tili sertifikati") + " (IELTS, TOEFL, CEFR)"}</h5>
            </Col>
            {data?.user?.language_certificate ? (
              <>
                <Col span={12}>
                  <div className='card' style={{ width: "100%", height: 500 }}>
                    <iframe
                      src={
                        FILE_URL +
                        data?.user?.application?.folder_name +
                        "/" +
                        data?.user?.language_certificate.certificate
                      }
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </Col>
                <Col
                  span={12}
                  className='d-flex flex-column justify-content-between'>
                  <div>
                    <p>
                      <strong>{t("O'quvchi sertfikati")}:</strong> &nbsp;{" "}
                      <span>
                        {
                          data?.user?.language_certificate
                            ?.language_certificate_category?.name
                        }
                      </span>
                    </p>
                    <p>
                      <strong>{t("Sertfikat bali")}:</strong> &nbsp;{" "}
                      <span>{data?.user?.language_certificate?.score}</span>
                    </p>

                    <p>
                      <strong>{t("Sertfikat darajasi")}:</strong> &nbsp;
                      <span>
                        {
                          data?.user?.language_certificate
                            ?.language_certificate_score?.score
                        }
                      </span>
                      {/* &nbsp;{"( "}
                        {Object.entries(
                          data?.user?.language_certificate
                            ?.language_certificate_score?.description
                        )?.map(([key, value], i) => (
                          <span key={i}>
                            {`${key}: ${value.join(" - ")} ${
                              i === 0 ? "," : ""
                            }`}{" "}
                            &nbsp;
                          </span>
                        ))}
                        {" ) "} */}
                    </p>
                  </div>
                  <div className='text-end'>
                    <InputNumber
                      min={0}
                      // max={30}
                      style={{ width: 200 }}
                      placeholder='Sertfikatni baholash ...'
                      value={data?.user?.language_certificate?.language_certificate_score?.ball}
                      onChange={(e) => setLangScore(e)}
                      readOnly
                    />
                    <Button
                      htmlType='submit'
                      type='primary'
                      className='mt-3 ms-3'
                      onClick={onLangCertificate}>
                      <FaCheck /> &nbsp; {t("Saqlash")}
                    </Button>
                  </div>
                </Col>
              </>
            ) : (
              <Empty description="Ma'lumot mavjud emas" />
            )}
          </Row>
        </Card>
        <Card className='car mt-3'>
          <div className='d-flex align-items-center'>
            <h5 className='mb-3'>{t("Sertifikat")}</h5>
          </div>
          {data?.user?.certificates?.length ? (
            <Form form={form} onFinish={onCertificate}>
              <div>
                {certificateCategory?.length
                  ? certificateCategory?.map((element, i) => (
                      <div key={i}>
                        <h6>
                          <strong>
                            {i + 1}. {element?.name[`${i18next.language}`]}
                          </strong>
                          &nbsp; &nbsp; <span> Max ball: {i === 0 ? "30" : "10"}</span>
                        </h6>
                        <List
                          size='small'
                          className='ms-3'
                          dataSource={element?.childs}
                          renderItem={(item) => (
                            <List.Item>
                              <div className='w-100'>
                                <div key={i}>
                                  {" "}
                                  <BsDot /> {
                                    item?.name[`${i18next.language}`]
                                  }{" "}
                                </div>
                                <p className='w-100 my-1 d-flex justify-content-between align-items-center'>
                                  <strong>
                                    Files:
                                    {data?.user?.certificates?.length
                                      ? data?.user?.certificates?.map((e, i) =>
                                          e?.certificate_category_id ===
                                          item?.id ? (
                                            <a
                                              key={i}
                                              href={
                                                FILE_URL +
                                                data?.user?.application
                                                  ?.folder_name +
                                                "/" +
                                                e?.certificate
                                              }
                                              target='_blank'>
                                              <Tag
                                                className='ms-2 my-1'
                                                color='blue'>
                                                {e?.certificate}
                                              </Tag>
                                            </a>
                                          ) : null
                                        )
                                      : null}
                                  </strong>
                                  {data?.user?.certificates?.filter(
                                    (e, i) =>
                                      e?.certificate_category_id === item?.id
                                  )?.length ? (
                                    <Form.Item
                                      name={`${item?.id}`}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Baholash majburiy",
                                        },
                                      ]}
                                      className='my-0'>
                                      <InputNumber
                                        min={0}
                                        style={{ width: 200 }}
                                        placeholder='Sertfikatni baholash ...'
                                      />
                                    </Form.Item>
                                  ) : null}
                                </p>
                              </div>
                            </List.Item>
                          )}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <div className='d-flex justify-content-end mt-3'>
                <Button htmlType='submit' type='primary' className='mx-2'>
                  <FaCheck /> &nbsp;{t("Saqlash")}
                </Button>
              </div>
            </Form>
          ) : (
            <Empty description="Ma'lumot mavjud emas" />
          )}
        </Card>
      </Spin>
    </div>
  );
};

export default SertficateTab;
