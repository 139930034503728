/** @format */

import React, { useState } from "react";
import "./layout.scss";
import { Layout, Menu } from "antd";
import CustomHeader from "../dashboard/header/Header";
import CustomContent from "../dashboard/content/Content";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import simpleLogo from "../../../assets/images/main_logo.png";
import logoMinjust from "../../../assets/images/minjust.png";
import { useEffect } from "react";
import useWindowSize from "../../../components/useWindowSize";

const { Sider } = Layout;
const { SubMenu } = Menu;

const CustomLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const path = useLocation()?.pathname;
  const auth = useSelector((state) => state.authRedux);
  const { t } = useTranslation();
  const width = useWindowSize().width;
  const ismobile = width < 768;

  useEffect(() => {
    if (ismobile) {
      setCollapsed(true);
    }
  }, []);

  useEffect(() => {
      localStorage.setItem("sidebar", collapsed);
  }, [collapsed]);


  return (
    <>
      <Layout className='site-layout' style={{ height: "100vh" }}>
        <Sider
          className='site-layout-background'
          trigger={null}
          collapsible
          collapsed={collapsed}
          defaultCollapsed={false}
          collapsedWidth='0'>
          <div
            style={{ width: "75%", height: "36px", margin: "14px" }}
            className='logo text-center d-flex justify-content-center align-items-center'>
            {/* <h3>TSUL</h3> */}
            <img className='img-fluid h-100 me-2' src={logoMinjust} alt='' />
            <img
              style={{ height: "32px" }}
              className='img-fluid'
              src={simpleLogo}
              alt=''
            />
          </div>
          <Menu
            className='site-layout-background text-white mt-1'
            mode='inline'
            defaultSelectedKeys={[path]}>
            {routes?.map((item, index) => {
              if (item?.config?.showLink) {
                if (
                  item?.config.key.includes("*") ||
                  item?.config?.key?.includes(auth?.role[0])
                ) {
                  return (
                    <>
                      <Menu.Item
                        key={item?.path}
                        onClick={() => history.push(item?.path)}
                        icon={item?.icon && item?.icon}
                        className=''>
                        {t(item?.name)}
                      </Menu.Item>
                    </>
                  );
                }
              }
            })}
          </Menu>
        </Sider>

        <Layout style={{ position: "relative" }}>
          <CustomHeader setCollapsed={setCollapsed} collapsed={collapsed} />
          <CustomContent>{children}</CustomContent>
        </Layout>
      </Layout>
    </>
  );
};

export default CustomLayout;
