import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import instance from "../../../api/axios";
import i18next, { i18n } from "i18next";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const EditSubject = () => {
  // hooks
  const history = useHistory();
  const path = useLocation()?.pathname;
  let a = path.split("/");
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      is_active: values?.is_active ? 1 : 0,
    };
    try {
      setLoader(true);
      const resp = await instance({
        method: "put",
        url: `/subjects/${a[a.length - 1]}`,
        data: values,
      });
      history.push("/subjects");
    } catch (error) {
      message.error("Ma'lumotni o'zgartirishda xatolik!");
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/subjects/${a[a.length - 1]}`,
        });
        if (resp.status === 200) {
          setLoading(false);
          form.setFieldsValue({
            ...resp?.data?.data,
            name_uz: resp?.data?.data?.name?.uz,
            name_ru: resp?.data?.data?.name?.ru,
            description_uz: resp?.data?.data?.description?.uz,
            description_ru: resp?.data?.data?.description?.ru,
            is_active: parseInt(resp.data?.data?.is_active) === 1,
          });
        }
      } catch (error) {
        setLoading(true);
        message.error("Fanni ochishda xatolik!");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <NavLink type="link" to={"/subjects"}>
                {t("Fanlar")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("O'zgartirish")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Spin spinning={loader}>
        <h3 orientation="center">{t("O'zgartirish")}</h3>
        <Skeleton active round={true} loading={loading} title={false}>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Row gutter={[16, 16]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Iltimos maydonlarni to`ldiring",
                    },
                  ]}
                  name="name_uz"
                  label={t("Nomi uz")}
                >
                  <Input placeholder="o'zbekcha nomi"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Iltimos maydonlarni to`ldiring",
                    },
                  ]}
                  name="name_ru"
                  label={t("Nomi ru")}
                >
                  <Input placeholder="ruscha nomi"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Iltimos maydonlarni to`ldiring",
                    },
                  ]}
                  name="course"
                  label={t("Kurs")}
                >
                  <Select defaultValue name="course">
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Iltimos maydonlarni to`ldiring",
                    },
                  ]}
                  name="description_uz"
                  label={t("Tavsif uz")}
                >
                  <Input placeholder="tavsif uz"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Iltimos maydonlarni to`ldiring",
                    },
                  ]}
                  name="description_ru"
                  label={t("Tavsif ru")}
                >
                  <Input placeholder="tavsif ru"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  name="is_active"
                  label={t("Holati")}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24} className="text-end">
                <Button htmlType="reset" className="btn-danger" type="primary">
                  {t("O'chirish")}
                </Button>
                <Button htmlType="submit" className="mx-3" type="primary">
                  {t("Saqlash")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Spin>
    </Fragment>
  );
};

export default EditSubject;
