export const getErrorTab = (inputName) => {
  let errorTab = [];
  errorTab[1] = [
    "name",
    "surname",
    "middle_name",
    "birth_date",
    "gender",
    "region_id",
    "district_id",
    "address",
    "telephone1",
    "telephone2",
    "photo",
  ];
  errorTab[2] = [
    "passport_serie",
    "passport_number",
    "passport_jshir",
    "passport_copy",
    "diploma_serie",
    "diploma_number",
    "diploma_given",
    "diploma_copy",
  ];
  errorTab[3] = [
    "texnikum_id",
    "university_id",
    "faculty_id",
    "education_type_id",
    "education_language_id",
  ];
  for (const key in errorTab) {
    if (errorTab[key].includes(inputName)) {
      return key;
    }
  }
};
export const getTabInputNames = (tabId) => {
    let errorTab = [];
  errorTab[1] = [
    "name",
    "surname",
    "middle_name",
    "birth_date",
    "gender",
    "region_id",
    "district_id",
    "address",
    "telephone1",
    "telephone2",
    "photo",
  ];
  errorTab[2] = [
    "passport_serie",
    "passport_number",
    "passport_jshir",
    "passport_copy",
    "diploma_serie",
    "diploma_number",
    "diploma_given",
    "diploma_copy",
  ];
  errorTab[3] = [
    "texnikum_id",
    "university_id",
    "faculty_id",
    "education_type_id",
    "education_language_id",
  ];
    return errorTab[tabId];
}
