import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import {
  Breadcrumb,
  Button,
  Col,
  Empty,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./subjects.scss";
import instance from "../../api/axios";
import { Fragment } from "react";

const Subjects = () => {
  //hooks
  const [subjects, setSubjects] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  // popconfirm
  const [visible, setVisible] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = (id) => {
    setVisible(id);
  };

  const handleCancel = () => {
    setVisible("");
  };

  // delete subjects
  const delete_subject = async (id) => {
    try {
      const resp = await instance({
        method: "delete",
        url: `/subjects/${id}`,
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error("Ma`lumotni o`chirishda xatolik!");
      setVisible("");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: "/subjects",
        });
        if (resp?.status === 200) {
          setSubjects(resp?.data?.data);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error("Error read education subjects!");
      }
    })();
  }, [refresh]);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("Fanlar")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col
          className="d-flex align-content-center justify-content-between"
          span={24}
        >
          <h3>{t("Fanlar")}</h3>
          <Button type="primary">
            <NavLink to={"/create_subject"}>{t("Qo'shish")} +</NavLink>
          </Button>
        </Col>

        <Skeleton loading={loading} active round={true}>
          {subjects?.length > 0 ? (
            <Table
              loading={true}
              className="table table-responsive table-bordered table-striped table-hover text-start"
            >
              <Thead className="table-head text-center">
                <Tr>
                  <Th>№</Th>
                  <Th>{t("Nomi uz")}</Th>
                  <Th>{t("Nomi ru")}</Th>
                  <Th>{t("Kurs")}</Th>
                  <Th>{t("Tavsif uz")}</Th>
                  <Th>{t("Tavsif ru")}</Th>
                  <Th>{t("Holati")}</Th>
                  <Th>{t("Amallar")}</Th>
                </Tr>
              </Thead>
              
              <Tbody>
                {subjects?.map((v, i) => {
                  return (
                    <Tr>
                      <Td className="text-center">{i + 1}</Td>
                      <Td>{v?.name?.uz}</Td>
                      <Td>{v?.name?.ru}</Td>
                      <Td className="text-center">{v?.course}</Td>
                      <Td>{v?.description?.uz}</Td>
                      <Td>{v?.description?.ru}</Td>
                      <Td className="text-center">
                        <Switch
                          checkedChildren={t("Faol")}
                          unCheckedChildren={t("Faol emas")}
                          size="small"
                          checked={v?.is_active === 1}
                        ></Switch>
                      </Td>
                      <Td className="text-center">
                        <NavLink to={"/edit_subject/" + v?.id} type="link">
                          <FaEdit />
                        </NavLink>
                        <Popconfirm
                          title={t("Ma'lumotni o'chirishni tasdiqlaysizmi?")}
                          okText={t("Ha")}
                          cancelText={t("Yo'q")}
                          visible={visible == v?.id}
                          onConfirm={() => delete_subject(v?.id)}
                          okButtonProps={{
                            loading: confirmLoading,
                          }}
                          onCancel={handleCancel}
                        >
                          <Button
                            onClick={() => showPopconfirm(v.id)}
                            className="text-danger"
                            type="link"
                          >
                            <FaTrashAlt />
                          </Button>
                        </Popconfirm>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <div className="w-100">
              <Empty description={t("Ma'lumot yo'q")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Skeleton>
      </Row>
    </Fragment>
  );
};

export default Subjects;
