import RoutesMiddleware from "./routes/middleware";
import { useEffect } from "react";
import instance from "./api/axios";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SAVE_USER } from "./redux/user/actionType";
import { useState } from "react";

function App() {
  const location=useLocation()
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("access_token")) {
        try {
          setLoader(true);
          const me = await instance({
            url: "/me",
          });
          if (me?.status === 200) {
            dispatch({
              type: SAVE_USER,
              payload: me?.data,
              role: me?.data?.role,
              isAuth: true,
            });
            history.push(location.pathname);
            setLoader(false);
          }
        } catch (e) {
          localStorage.removeItem("access_token");
          history.push("/login")
        }
      }else{
        history.push("/login")
      }
    })();
  }, []);
  return (
    <>
      {loader ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: "55px", height: "55px" }}
            className="spinner-border text-primary"
            role="status"
          >
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <RoutesMiddleware />
      )}
    </>
  );
}

export default App;
