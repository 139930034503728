import React from "react";
import instance from "../../api/axios";

export const statisticForCheckerApi = async (params = {}) => {
  try {
    const response = await instance({
      method: "GET",
      url: "/applications-statistics-for-checker",
      params:params
    });
    return response?.data?.data;
  } catch (err) {}
};


