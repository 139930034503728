import React from 'react';
import { Route } from 'react-router-dom';
import CustomLayout from '../containers/structure/layout/Layout';
import NonLayout from '../containers/structure/layout/NonLayout';

const RenderComponent = ({ component: Component, layout }) => {

    return (
        <Route
            render={(props) => {
                if (layout) {
                    return (
                        <CustomLayout>
                            <Component {...props} />
                        </CustomLayout>
                    )
                } else {
                    return (
                        <NonLayout>
                            <Component {...props} />
                        </NonLayout>
                    )
                }

            }}
        />
    )
}

export default RenderComponent;