/** @format */

import { Col, Divider, message, Row, Skeleton, Switch, Tabs, Tag } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import instance from "../../api/axios";
import EmptyData from "../../components/emptyData";

const { TabPane } = Tabs;

const ConversationScore = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState();
  const [converStatus, setConverStatus] = useState();
  const [scoreVisible, setScoreVisible] = useState();
  const tabs = ["Hammasi", "Baholangan", "Baholanmagan", "Qisman baholanmagan"];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/applications-for-texnikum-admin`,
        });
        setStudents(resp?.data?.data?.data);
        setTotal(resp?.data?.data?.total);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error(t("Malumot olishda xatolik!"));
      }
    })();
  }, [i18n.language]);

  useEffect(()=>{
    (async () => {
      const res = await instance({
        method: "get",
        url: `/conversation-status`,
      });

      setConverStatus(res.data.data.is_conversation_processing)
      setScoreVisible(res.data.data.is_conversation_ball_visible)
    })()
  },[])

  const postConverStatus = async () => {
    const res = await instance({
      method: "post",
      url: "/conversation-status-change",
    });

    if(res.data.status === 'Success')
      message.success(res.data.message)
    else
      message.error(res.data.message)
  }

  const postScoreVisible = async () => {
    const res = await instance({
      method: "post",
      url: "/conversation-ball-visible-status-change",
    });

    if(res.data.status === 'Success')
      message.success(res.data.message)
    else
      message.error(res.data.message)
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <h5 className='mb-3'>{t("Talabalar bazasi")}</h5>
        <div>
          <div className=" d-flex align-items-center justify-content-between">
            <strong className='me-3'>
              {t("Suhbatni baholash holati")}:{" "}
            </strong>
            <Switch
              checkedChildren={t("yoqish")}
              unCheckedChildren={t("o'chirish")}
              checked={converStatus}
              onChange={(e) => {setConverStatus(e); postConverStatus()}}
            />
          </div>
          <div className="mt-2 d-flex align-items-center justify-content-between">
            <strong className='me-3'>
              {t("Suhbat baholrini o'quvchiga ko'rinishi")}:{" "}
            </strong>
            <Switch
              checkedChildren={t("yoqish")}
              unCheckedChildren={t("o'chirish")}
              checked={scoreVisible}
              onChange={(e) => {setScoreVisible(e); postScoreVisible()}}
            />
          </div>
        </div>
      </div>
      <Divider className='my-2 mb-3' />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Tabs>
            {tabs.map((tab, index) => (
              <TabPane tab={t(tab)} key={`${index}`}>
                {loading ? (
                  <Skeleton active />
                ) : (
                  <Table className='table table-responsive table-bordered table-striped table-hover'>
                    <Thead>
                      <Tr>
                        <Th>№</Th>
                        <Th>{t("FIO")}</Th>
                        <Th>{t("Pasport seriya va raqam")}</Th>
                        <Th>{t("Telefon raqam")}</Th>
                        <Th>{t("Topshirilgan sana")}</Th>
                        <Th>{t("Status")}</Th>
                        <Th>{t("Amallar")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {students?.length
                        ? students?.map((item, i) =>
                            (index === 1 &&
                              item?.texComissionBallStatus === 1) ||
                            (index === 2 &&
                              item?.texComissionBallStatus === 0) ||
                            (index === 3 &&
                              item?.texComissionBallStatus === 2) ||
                            index === 0 ? (
                              <Tr key={i}>
                                <Td style={{ width: 40 }}>{i + 1}</Td>
                                <Td>
                                  <NavLink
                                    to={`/conversation_score_view/${item?.id}`}
                                    type='link'
                                    className='name_link'>
                                    {item?.name} {item?.surname}{" "}
                                    {item?.middle_name}
                                  </NavLink>
                                </Td>
                                <Td>
                                  <span>
                                    {item?.passport_serie}{" "}
                                    {item?.passport_numbers}
                                  </span>
                                </Td>

                                <Td>{item?.telephone}</Td>
                                <Td>{item?.created_at}</Td>
                                <Td>
                                  {item?.texComissionBallStatus === 1 ? (
                                    <Tag color='green'>{t("Baholangan")}</Tag>
                                  ) : item?.texComissionBallStatus === 0 ? (
                                    <Tag color='red'>{t("Baholanmagan")}</Tag>
                                  ) : item?.texComissionBallStatus === 2 ? (
                                    <Tag color='orange'>
                                      {t("Qisman baholangan")}
                                    </Tag>
                                  ) : null}
                                </Td>
                                <Td
                                  className='text-center'
                                  style={{ width: 60 }}>
                                  <NavLink
                                    to={`/conversation_score_view/${item?.id}`}
                                    type='link'>
                                    <FaEye size={16} className='my-1 mb-2' />
                                  </NavLink>
                                </Td>
                              </Tr>
                            ) : null
                          )
                        : null}
                    </Tbody>
                  </Table>
                )}
                {total ? <Tag>Jami: {total} ta</Tag> : null}
                <EmptyData data={students} loading={loading} />
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ConversationScore;
