import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import instance from "../../../api/axios";
import "../educationlang.scss";

const EditEduLangs = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const url = useLocation().pathname;
  let a = url.split("/");
  const [language, setLanguage] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    values = {
      ...values,
      status: values?.status ? 1 : 0,
    };
    try {
      const resp = await instance({
        method: "put",
        url: `/edu-langs/${a[a.length - 1]}`,
        data: values,
      });
      history.push("/education_language");
    } catch (error) {
      message.error("Talim tilini o'zgartirishda xatolik!");
    }
  };

  // get education language detail
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/edu-langs/${a[a.length - 1]}`,
        });
        if (resp.status === 200) {
          setLoading(false);
          form.setFieldsValue({
            name_uz: resp?.data["edu-type"]?.name?.uz,
            name_ru: resp?.data["edu-type"]?.name?.ru,
            description: resp?.data["edu-type"]?.description,
            status: parseInt(resp?.data["edu-type"]?.status) === 1,
          });
        }
      } catch (error) {
        setLoading(false);
        message.error("Error read education language");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <NavLink type="link" to={"/education_language"}>
                {t("Ta'lim tili")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("O'zgartirish")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h3>{t("Ta'lim tilini o'zgartirish")}</h3>
        </Col>
      </Row>
      <Spin spinning={loader}>
        <Skeleton loading={loading} active round={true} paragraph={false}>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Row className="add-edu-lang" gutter={[16, 16]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="name_uz" label={t("Nomi uz")}>
                  <Input placeholder={t("o'zbekcha nomi")}></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="name_ru" label={t("Nomi ru")}>
                  <Input placeholder="ruscha nomi"></Input>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item name="description" label={t("Tavsif")}>
                  <Input placeholder="tavsif"></Input>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  name="status"
                  label={t("Holati")}
                  valuePropName="checked"
                >
                  <Switch unCheckedChildren={t("Faol emas")}
                  checkedChildren={t("Faol")} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24} className="text-end">
                <Button htmlType="reset" className="btn-danger" type="primary">
                  {t("O'chirish")}
                </Button>
                <Button htmlType="submit" className="mx-3" type="primary">
                  {t("Saqlash")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Spin>
    </Fragment>
  );
};

export default EditEduLangs;
