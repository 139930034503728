import React, { useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import {
  Breadcrumb,
  Button,
  Col,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Switch,
} from "antd";
import { NavLink } from "react-router-dom";
import instance from "../../api/axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const EducationLanguage = () => {
  // hooks
  const [eduLangs, setEduLangs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  // popconfirm
  const [visible, setVisible] = React.useState("");
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = (id) => {
    setVisible(id);
  };

  const handleCancel = () => {
    setVisible("");
  };

  // delete education language
  const delete_edu_lang = async (id) => {
    try {
      const resp = await instance({
        method: "delete",
        url: `/edu-langs/${id}`,
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error("Ma`lumotni o`chirishda xatolik!");
      setVisible("");
    }
  };

  // get edu languages
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: "/edu-langs",
        });
        if (resp?.status === 200) {
          setLoading(false);
          setEduLangs(resp?.data?.edu_langs);
        }
      } catch (e) {
        setLoading(false);
        message.error("Error read education languages!");
      }
    })();
  }, [refresh]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("Ta'lim tili")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col
          className="d-flex align-content-center justify-content-between"
          span={24}
        >
          <h3>{t("Ta'lim tili")}</h3>
          <Button type="primary">
            <NavLink to={"/create_edu_langs"}>{t("Qo'shish")} +</NavLink>
          </Button>
        </Col>
        <Skeleton loading={loading} active round={true}>
          <Table className="table table-responsive table-bordered table-striped table-hover text-center">
            <Thead className="table-head">
              <Tr>
                <Th>№</Th>
                <Th>{t("Nomi uz/ru")}</Th>
                <Th>{t("Tavsif")}</Th>
                <Th>{t("Holati")}</Th>
                <Th>{t("Amallar")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {eduLangs?.map((v, i) => {
                return (
                  <Tr>
                    <Td>{i + 1}</Td>
                    <Td>{v?.name[[i18next.language]]}</Td>
                    <Td>{v?.description}</Td>
                    <Td>
                      <Switch
                        checkedChildren={t("Faol")}
                        unCheckedChildren={t("Faol emas")}
                        size="small"
                        checked={v?.status === 1}
                      ></Switch>
                    </Td>
                    <Td>
                      <NavLink to={"/edit_edu_langs/" + v?.id} type="link">
                        <FaEdit />
                      </NavLink>
                      <Popconfirm
                        title={t("Ma'lumotni o'chirishni tasdiqlaysizmi?")}
                        okText={t("Ha")}
                        cancelText={t("Yo'q")}
                        visible={visible == v?.id}
                        onConfirm={() => delete_edu_lang(v?.id)}
                        okButtonProps={{
                          loading: confirmLoading,
                        }}
                        onCancel={handleCancel}
                      >
                        <Button
                          onClick={() => showPopconfirm(v.id)}
                          className="text-danger"
                          type="link"
                        >
                          <FaTrashAlt />
                        </Button>
                      </Popconfirm>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Skeleton>
      </Row>
    </>
  );
};

export default EducationLanguage;
