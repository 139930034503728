import React from "react";
import instance from "../../api/axios";

export const languageCertificateCategoryIndex = async (params = {}) => {
    try {
        const response = await instance({
            method: "GET",
            url: "/language-certificate-categories",
        });
        return response;
    } catch (err) {
    }
};

export const otherCertificateCategoryIndex = async (params = {}) => {
    try {
        const response = await instance({
            method: "GET",
            url: "/certificate-categories",
        });
        return response;
    } catch (err) {
    }
};