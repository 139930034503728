import styled from "styled-components"
const LineLoading = ({loading}) => {

  return(
    <Wrapper>
      { loading ? <div class="blue"></div> : null}
    </Wrapper>
  )
}

export default LineLoading;

const Wrapper = styled.div`
	width: 100%;
	height: 4px;
	position: relative;
  /* border: 1px solid #ccc; */
.blue{
	position: absolute;
	background: blue;
	top: 0;
	bottom: 0;
	/* left: 0; */
	animation-name: anime;
	animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes anime {
  0%{
		left: 0;
		width: 0;
	}
  30%{
    width: 40%;
  }
  100%{
    left: 100%;
    width: 10px;
  }
}
`