export const interval_ball = (ball, texEduType = 0) => {
    if (texEduType === 0) {
        if (parseFloat(ball) >= 71 && parseFloat(ball) <= 75.9) {
            return 10;
        } else if (parseFloat(ball) >= 76 && parseFloat(ball) <= 85.9) {
            return 20;
        } else if (parseFloat(ball) >= 86 && parseFloat(ball) <= 100) {
            return 30;
        } else {
            return 0;
        }
    } else {
        if (parseFloat(ball) >= 85 && parseFloat(ball) <= 90.9) {
            return 25;
        } else if (parseFloat(ball) >= 91 && parseFloat(ball) <= 100) {
            return 50;
        } else {
            return 0;
        }
    }

}

export const parentCertificateBall = (data) => {
    let score = 0;

    data?.childs?.forEach((elementChild, indexChild) => {
        if (elementChild?.scores?.length > 0) {
            score += parseFloat(elementChild?.scores[0]?.score)
        }
    })
    return score;
}
export const allOtherCertificateBall = (allData) => {
    let score = 0;
    allData?.forEach((data, index) => {
        data?.childs?.forEach((elementChild, indexChild) => {
            if (elementChild?.scores?.length > 0) {
                score += parseFloat(elementChild?.scores[0]?.score)
            }
        })
    })
    return score;
}

export const allConversationBallsSum = (application) => {
    let ball = 0;
    let counter = 0;
    application?.conversation_balls?.forEach((el) => {
        ball += parseFloat(el?.ball);
        counter++;
    })
    if (counter) {
        return (ball / counter).toFixed(2);
    } else {
        return 0;
    }
}

export const getLanguageCertificateScore = (languageCertificate) => {
    return parseFloat(languageCertificate?.language_certificate_score?.ball);
}