import {
  Breadcrumb,
  Button,
  Card,
  Col,
  message,
  Row,
  Skeleton,
  Switch,
} from "antd";
import i18next from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import instance from "../../../api/axios";
import { AiOutlineCopy } from "react-icons/ai";
import openNotification from "../../../components/Notification";
const { Meta } = Card;

const ReadTexnikum = () => {
  // hooks
  const { t, i18n } = useTranslation();
  const path = useLocation().pathname;
  const pathParts = path?.split("/");
  const [texnikum, setTexnikum] = useState(null);
  const [loading, setLoading] = useState(true);

  //   skeleton
  const onChange = (checked) => {
    setLoading(!checked);
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: `/texnikum/${
            pathParts[pathParts?.length - 1]
          }?expand=admin.credentials(user_id|login|password)`,
        });
        setTexnikum(resp?.data?.data);
      } catch (e) {
        // message.error("Error get your application");
      }
    })();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <NavLink type="link" to={"/texnikumlar"}>
                {t("Texnikumlar")}
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("Texnikum ma'lumotlari")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col span={24}>
          <div className="site-card-border-less-wrapper mt-3 position-relative">
            <Card
              title={texnikum && texnikum?.name[i18n.language]}
              extra={
                <Switch
                  checkedChildren={t("Yopish")}
                  unCheckedChildren={t("Ko'rish")}
                  checked={!loading}
                  onChange={onChange}
                />
              }
              bordered={true}
              style={{
                width: 600,
                height: 300,
                borderRadius: "15px",
              }}
            >
              <p className="fw-bold">
                {t("Foydalanuvchi nomi")}:{" "}
                <span className="fw-normal">
                  {texnikum && texnikum?.admin?.name}
                </span>
              </p>

              <Skeleton loading={loading} round={true} block title={false}>
                <p className="fw-bold position-relative">
                  {t("Login")}:{" "}
                  <span className="fw-normal">
                    {texnikum && texnikum?.admin?.credentials?.login}
                  </span>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        texnikum && texnikum?.admin?.credentials?.login
                      );
                      openNotification(
                        "bottom",
                        `${t("Login nusxalandi")}`,
                        "1"
                      );
                    }}
                    className="ms-3 rounded"
                    type="secondary"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <AiOutlineCopy />
                  </Button>
                </p>

                <p className="fw-bold">
                  {t("Parol")}:{" "}
                  <span className="fw-normal">
                    {texnikum && texnikum?.admin?.credentials?.password}
                  </span>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        texnikum && texnikum?.admin?.credentials?.password
                      );
                      openNotification(
                        "bottom",
                        `${t("Parol nusxalandi")}`,
                        "1"
                      );
                    }}
                    className="ms-3 rounded"
                    type="secondary"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <AiOutlineCopy />
                  </Button>
                </p>
              </Skeleton>
              <p
                className="fw-bold small position-absolute"
                style={{ bottom: "0" }}
              >
                {t("Yaratilgan")}:{" "}
                <span className="fw-normal text-secondary">
                  {texnikum && texnikum?.admin?.created_at}
                </span>
              </p>
            </Card>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ReadTexnikum;
