/** @format */

import {
  Badge,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Skeleton,
  Spin,
  Tabs,
  Tag,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import instance from "../../api/axios";
import { FILE_URL } from "../../api/constants";
import EmptyData from "../../components/emptyData";
import useDebounce from "../../components/useDebounce";
import "./style.scss";

const { TabPane } = Tabs;
const { Search } = Input;

const StudentBace = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  // const [perPage, setPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);
  const [tabs, setTabs] = useState([
    {
      name:"Hammasi",
      count: 0
    },
    {
      name:"Yangi",
      count: 0
    },
    {
      name:"Baholangan",
      count: 0
    },
    {
      name:"qaytarilgan",
      count: 0
    },
    {
      name:"Tasdiqlangan",
      count: 0
    }
  ]);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    (async () => {
      try{
        const response = await instance({
          method: "get",
          url: "/texnikum-admin/report",
        });
        tabs.map((tab, i) => {
          tab.count = response.data.data[i];
        })
        setTabs(tabs);
      } catch (e){

      }
  })();
  },[]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await instance({
          method: "get",
          url: `/texnikum-admin/students?expand=application.universities,texStatus${Number(status) ? `&status=${status - 1}` : ""}${debouncedSearch ? `&search=${debouncedSearch}` : ""}`,
          // params: { "per-page": perPage, page: currentPage },
        });
        setStudents(resp?.data?.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error(t("Malumot olishda xatolik!"));
      }
    })();
  }, [debouncedSearch, i18n.language, status]);

  useEffect(() => {
    setStatus(sessionStorage.getItem("tab_status") ? sessionStorage.getItem("tab_status") : "0");
    setSearch(sessionStorage.getItem("search_text") ? sessionStorage.getItem("search_text") : "");
  },[]);

  const changeStatus = (status) => {
    sessionStorage.setItem("tab_status", status);
  }
  const changeSearch = (search) => {
    setTimeout(sessionStorage.setItem("search_text", search), 500);
  }


  console.log(tabs);
  return (
    <div>
      <h5 className="mb-3">{t("Talabalar bazasi")}</h5>
      <Divider className="my-2 mb-3" />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="text-end">
            <Search
              value={search}
              allowClear
              placeholder="F.I.O. va pasport seriya raqam orqali qidirish ... "
              style={{ width: 400 }}
              onChange={(e) => {setSearch(e.target.value); changeSearch(e.target.value)}}
            />
          </div>
          <Tabs
            activeKey={String(status)}
            onChange={(e) => {setStatus(e); changeStatus(e)}}
          >
            {tabs.map((tab, index) => (
              <TabPane tab={<Badge color="blue" offset={[10,0]} count={tab?.count} >{t(tab?.name)}</Badge>} key={`${index}`}>
                {loading ? (
                  <Skeleton active />
                ) : (
                  <Table className="table table-responsive table-bordered table-striped table-hover">
                    <Thead>
                      <Tr>
                        <Th>№</Th>
                        <Th>{t("FIO")}</Th>
                        <Th>{t("Pasport seriya va raqam")}</Th>
                        <Th>{t("Diplom seriya va raqam")}</Th>
                        <Th>{t("Telefon raqam")}</Th>
                        <Th>{t("Topshirilgan sana")}</Th>
                        <Th>{t("Status")}</Th>
                        <Th>{t("Amallar")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {students?.length
                        ? students?.map((item, i) => (
                            <Tr key={i}>
                              <Td style={{ width: 40 }}>{i + 1}</Td>
                              <Td>
                                <NavLink
                                  to={`/student_check/${item?.id}`}
                                  type="link"
                                  className="name_link"
                                >
                                  {item?.application?.name}{" "}
                                  {item?.application?.surname}{" "}
                                  {item?.application?.middle_name}
                                </NavLink>
                              </Td>
                              <Td>
                                <span>
                                  {item?.application?.passport_serie}{" "}
                                  {item?.application?.passport_numbers}
                                </span>
                              </Td>
                              <Td>
                                {item?.application?.diploma_serie}{" "}
                                {item?.application?.diploma_number}
                              </Td>
                              <Td>{item?.application?.telephone}</Td>
                              <Td>{item?.application?.created_at}</Td>
                              <Td>
                                {item?.tex_status?.status === 0 ? (
                                  <Tag color="orange">
                                    {t("Tekshirilmagan")}
                                  </Tag>
                                ) : item?.tex_status?.status === 1 ? (
                                  <Tag color="blue">{t("Baholangan")}</Tag>
                                ) : item?.tex_status?.status === 2 ? (
                                  <Tag color="red">{t("Qaytarilgan")}</Tag>
                                ) : item?.tex_status?.status === 3 ? (
                                  <Tag color="green">{t("Qabul qilingan")}</Tag>
                                ) : null}
                              </Td>
                              <Td className="text-center" style={{ width: 60 }}>
                                <NavLink
                                  to={`/student_check/${item?.id}`}
                                  type='link'>
                                  <FaEye size={16} className='my-1 mb-2' />
                                </NavLink>
                              </Td>
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                )}
                {students?.length ? <Tag>Jami {students?.length} ta</Tag> : null}
                <EmptyData data={students} loading={loading} />
                {students?.length > 10 ? (
                  <div className="text-end">
                    <Pagination
                      defaultCurrent={1}
                      // current={currentPage}
                      // total={perPage}
                    />
                  </div>
                ) : null}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default StudentBace;
