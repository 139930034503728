/** @format */

import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Radio,
  Row,
  Tabs,
  Tag,
} from "antd";
import React from "react";
import { useState, useEffect } from "react";
import instance from "../../../api/axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import "../checker.scss";
import { FILE_URL } from "../../../api/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SertficateTab from "./sertficateTab";
const { TabPane } = Tabs;

const { TextArea } = Input;

const ApplicantViewer = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [applications, setApplications] = useState({});
  const [errors, setErrors] = useState([]);
  const location = useLocation()?.pathname;
  let a = location?.split("/");
  const [_id, setId] = useState();
  const [fileUrls, setFileUrls] = useState();
  const { t } = useTranslation();
  const [universities, setUniversities] = useState([]);
  const [fieldsObj, setFieldsObj] = useState([
    { name: "name", error: "error-name", title: "Ismi" },
    { name: "surname", error: "error-surname", title: "Familyasi" },
    {
      name: "middle_name",
      error: "error-middle_name",
      title: "Otasining ismi",
    },
    { name: "birthdate", error: "error-birth_date", title: "Tug'ulgan sana" },
    { name: "gender_name", error: "error-gender", title: "Jinsi" },
    { name: "region_name", error: "error-regions", title: "Viloyat" },
    { name: "district_name", error: "error-district", title: "Tuman" },
    { name: "address", error: "error-address", title: "Manzil" },
    { name: "telephone", error: "error-telephone", title: "Telefon 1" },
    { name: "telephone2", error: "error-telephone2", title: "Telefon 2" },
    {
      name: "passport_serie",
      error: "error-passport_serie",
      title: "Pasport seriyasi",
    },
    {
      name: "passport_numbers",
      error: "error-passport_numbers",
      title: "Pasport raqami",
    },
    {
      name: "passport_jshr",
      error: "error-passport_jshr",
      title: "Pasport JSHSHIR",
    },
    {
      name: "diploma_serie",
      error: "error-diploma_serie",
      title: "Diplom/Attestat seriyasi",
    },
    {
      name: "diploma_number",
      error: "error-diploma_number",
      title: "Diplom/Attestat raqam",
    },
    { name: "texnikum_name", error: "error-texnikum_id", title: "Texnikumi" },
    {
      name: "faculty_name",
      error: "error-faculty_id",
      title: "Ta`lim yo`nalishi",
    },
    {
      name: "tex_edu_type",
      error: "error-tex_edu_type",
      title: "Texnikum ta'lim shakli(bitirgan)",
    },
    {
      name: "education_language_name",
      error: "error-education_language_id",
      title: "Ta'lim tili",
    },
    {
      name: "education_type_name",
      error: "error-education_type_id",
      title: "Ta'lim shakli(topshirgan)",
    },
  ]);

  const [general, setGeneral] = useState();
  // change status function
  const changeStatus = async () => {
    try {
      const resp = await instance({
        method: "post",
        url: `checker/application/status/change/${a[a.length - 1]}?status=3`,
      });

      if (resp?.data?.status === "Success") {
        message.success(resp?.data?.message);
        history.push("/checker");
      }
    } catch (e) {
      message.error(e?.response?.data?.message);
    }
  };

  const onFinish = async (values) => {
    const data = new FormData();

    let ac = [];
    Object.entries(values)?.forEach(([key, value]) => {
      if (key.startsWith("error")) {
        let b = key.split("-");
        ac = [
          ...ac,
          {
            name: b[1],
            comment: value,
          },
        ];
      }
    });

    let id = a[a.length - 1];
    data.append("errors", JSON.stringify(ac));
    if (ac.length > 0) {
      try {
        const resp = await instance({
          method: "post",
          url: "/checker/application/feedback/" + id,
          data: data,
        });
        history.push("/checker");
      } catch (e) {
        message?.error("Xatoliklarni yuborishda xatolik!");
      }
    } else {
      message.error("Kamida bitta noto'g'ri maydonni belgilang!");
    }
  };

  // get applications data
  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url:
            "/checker/applications/" +
            a[a.length - 1] +
            "?expand=region,district,education_type,education_language,faculty,feedbacks_latest,universities,texnikum",
        });
        if (resp?.data?.status === "Success") {
          setGeneral(resp?.data?.data);
          const data = resp?.data?.data;
          setId(data?.user_id);
          setUniversities(data?.universities);
          form.setFieldsValue({
            ...data,
            tex_edu_type:
              data?.tex_edu_type == 1 ? `${t("Sirtqi")}` : `${t("Kunduzgi")}`,
            gender_name: data?.gender === 0 ? "Female" : "Male",
            region_name: data?.region?.name,
            district_name: data?.district?.name,
            faculty_name: data?.faculty?.name[i18next.language],
            education_language_name:
              data?.education_language?.name[i18next.language],
            education_type_name: data?.education_type?.name[i18next.language],
            texnikum_name: data?.texnikum?.name[i18next.language],
          });
          setFileUrls({
            image_url: data?.folder_name + "/" + data?.photo,
            passport_url: data?.folder_name + "/" + data?.passport_copy,
            diplom_url: data?.folder_name + "/" + data?.diploma_copy,
            qayta_tayyorlov_sertifikat: data?.qayta_tayyorlov_sertifikat
              ? data?.folder_name + "/" + data?.qayta_tayyorlov_sertifikat
              : undefined,
          });
          if (data?.feedbacks_latest) {
            let errors = [];
            data?.feedbacks_latest?.map((item) => {
              errors = [
                ...errors,
                {
                  name: [item?.column_name],
                  errors: [item?.feedback],
                },
              ];
            });
            form.setFields(errors);
          }
        }
      } catch (e) {
        message.error("Hujjatni ochishda xatolik!");
      }
    })();
  }, [i18next.language]);

  //   JSX
  return (
    <>
      <Col span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink type="link" to={"/"}>
              {t("Asosiy")}
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink type="link" to={"/checker"}>
              {t("Hujjatlar hisoboti")}
            </NavLink>
          </Breadcrumb.Item>

          <Breadcrumb.Item>{t("Ma'lumotlarni tekshirish")}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col
        className="d-flex align-items-center justify-content-between"
        span={24}
      >
        <h5 className="header my-2">{t("Ma'lumotlarni tekshirish")}</h5>
        <Tag className="fs-6" color="blue">
          {t("O'quvchi olgan ball")}: {general?.subjects_overall_score}
        </Tag>
      </Col>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("Hujjatlarni tekshirish")} key="1">
          <Form name="basic" form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              {fieldsObj?.map((value, i) =>
                (value.name === "diploma_serie" &&
                  !form.getFieldValue(value.name)) ||
                (value.name === "diploma_number" &&
                  !form.getFieldValue(value.name)) ? null : (
                  <Col
                    key={i}
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    <Row gutter={24}>
                      <Col span={24} className="d-flex align-items-center">
                        <div
                          style={{ width: "100%" }}
                          className="d-flex align-items-center"
                        >
                          <Form.Item
                            style={{ width: "100%" }}
                            name={value.name}
                            label={t(value.title)}
                          >
                            <Input readOnly />
                          </Form.Item>

                          {value.name !== "texnikum_name" ? (
                            <Button
                              onClick={() => {
                                if (!errors?.includes(`${value.error}`)) {
                                  setErrors([...errors, `${value.error}`]);
                                } else {
                                  setErrors([
                                    ...errors?.filter(
                                      (item) => item !== `${value.error}`
                                    ),
                                  ]);
                                  form.setFieldsValue({
                                    [`${value.error}`]: "",
                                  });
                                }
                              }}
                              danger
                              className="ms-2 btn-md"
                              style={{ marginTop: "7px" }}
                            >
                              <AiOutlineCloseCircle />
                            </Button>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {errors?.includes(`${value.error}`) && (
                      <Row gutter={24}>
                        <Col span={24}>
                          <Form.Item
                            name={`${value.error}`}
                            label={`Comment for ${value.title}`}
                          >
                            <TextArea maxLength={100} showCount />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Col>
                )
              )}
              {/* Files and photos */}
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Row gutter={24}>
                  <Col span={24} className="d-flex align-items-center">
                    <div
                      style={{ width: "100%" }}
                      className="d-flex align-items-center"
                    >
                      <Form.Item
                        style={{ width: "100%" }}
                        name={"photo"}
                        label={t("Rasmi")}
                      >
                        <Button type="dashed" style={{ width: "100%" }}>
                          <a
                            href={FILE_URL + fileUrls?.image_url}
                            download
                            target="_blank"
                          >
                            <GrView />
                          </a>
                        </Button>
                      </Form.Item>

                      <Button
                        onClick={() => {
                          if (!errors?.includes("error-photo")) {
                            setErrors([...errors, "error-photo"]);
                          } else {
                            setErrors([
                              ...errors?.filter(
                                (item) => item !== "error-photo"
                              ),
                            ]);
                            form.setFieldsValue({ ["error-photo"]: "" });
                          }
                        }}
                        danger
                        className="ms-2 btn-md"
                        style={{ marginTop: "7px" }}
                      >
                        <AiOutlineCloseCircle />
                      </Button>
                    </div>
                  </Col>
                </Row>
                {errors.includes("error-photo") && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name={"error-photo"}
                        label={"Comment for photo"}
                      >
                        <TextArea maxLength={100} showCount />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Row gutter={24}>
                  <Col span={24} className="d-flex align-items-center">
                    <div
                      style={{ width: "100%" }}
                      className="d-flex align-items-center"
                    >
                      <Form.Item
                        style={{ width: "100%" }}
                        name={"passport_copy"}
                        label={t("Pasport nusxasi")}
                      >
                        <Button type="dashed" style={{ width: "100%" }}>
                          <a
                            href={FILE_URL + fileUrls?.passport_url}
                            download
                            target="_blank"
                          >
                            <GrView />
                          </a>
                        </Button>
                      </Form.Item>

                      <Button
                        onClick={() => {
                          if (!errors?.includes("error-passport_copy")) {
                            setErrors([...errors, "error-passport_copy"]);
                          } else {
                            setErrors([
                              ...errors?.filter(
                                (item) => item !== "error-passport_copy"
                              ),
                            ]);
                            form.setFieldsValue({
                              ["error-passport_copy"]: "",
                            });
                          }
                        }}
                        danger
                        className="ms-2 btn-md"
                        style={{ marginTop: "7px" }}
                      >
                        <AiOutlineCloseCircle />
                      </Button>
                    </div>
                  </Col>
                </Row>
                {errors.includes("error-passport_copy") && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name={"error-passport_copy"}
                        label={"Comment for passport copy"}
                      >
                        <TextArea maxLength={100} showCount />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Row gutter={24}>
                  <Col span={24} className="d-flex align-items-center">
                    <div
                      style={{ width: "100%" }}
                      className="d-flex align-items-center"
                    >
                      <Form.Item
                        style={{ width: "100%" }}
                        name={"diploma_copy"}
                        label={t("Diplom/Attestat va ilova nusxasi")}
                      >
                        <Button type="dashed" style={{ width: "100%" }}>
                          <a
                            href={FILE_URL + fileUrls?.diplom_url}
                            download
                            target="_blank"
                          >
                            <GrView />
                          </a>
                        </Button>
                      </Form.Item>

                      <Button
                        onClick={() => {
                          if (!errors?.includes("error-diploma_copy")) {
                            setErrors([...errors, "error-diploma_copy"]);
                          } else {
                            setErrors([
                              ...errors?.filter(
                                (item) => item !== "error-diploma_copy"
                              ),
                            ]);
                            form.setFieldsValue({ ["error-diploma_copy"]: "" });
                          }
                        }}
                        danger
                        className="ms-2 btn-md"
                        style={{ marginTop: "7px" }}
                      >
                        <AiOutlineCloseCircle />
                      </Button>
                    </div>
                  </Col>
                </Row>
                {errors.includes("error-diploma_copy") && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name={"error-diploma_copy"}
                        label={"Comment for diplom copy"}
                      >
                        <TextArea maxLength={100} showCount />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={24} className="mb-3">
                <Divider className="mt-0" orientation="left">
                  {t("Universitetlar")}
                </Divider>
                <Row gutter={16}>
                  {universities?.length
                    ? universities?.map((univ, i) => (
                        <Col span={4} key={i}>
                          <Input
                            readOnly
                            value={univ?.name[i18next.language]}
                          />
                        </Col>
                      ))
                    : null}
                </Row>
              </Col>
            </Row>
            {/* Submit button */}
            <Row gutter={24}>
              <Col className="text-end" span={24}>
                <Button className="btn-danger" htmlType="submit" type="primary">
                  {t("Qaytarish")}
                </Button>
                <Button
                  onClick={changeStatus}
                  className="mx-3"
                  htmlType="button"
                  type="primary"
                >
                  {t("Qabul qilish")}
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={t("Sertifikatlarni tekshirish")} key="2">
          <SertficateTab _id={_id} general={general} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ApplicantViewer;
