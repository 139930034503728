import React from "react";
import instance from "../../api/axios";

export const certificateIndex = async (params = {}) => {
  try {
    const response = await instance({
      method: "GET",
      url: "/language-certificate",
      params:{
        expand:'languageCertificateCategory'
      }
    });
    return response;
  } catch (err) {}
};

export const otherCertificateIndex = async (params = {}) => {
  try {
    const response = await instance({
      method: "GET",
      url: "/certificate",
    });
    return response;
  } catch (err) {}
};

export const certificateStore = async (data, params = {}) => {
  try {
    const response = await instance({
      method: "POST",
      url: "/language-certificate",
      data: data,
    });
    return response;
  } catch (err) {return err}
};

export const otherCertificateStore = async (data, params = {}) => {
  try {
    const response = await instance({
      method: "POST",
      url: "/certificate",
      data: data,
    });
    return response;
  } catch (err) {
    return err;
  }
};
export const otherCertificateDelete = async (elementId) => {
  try {
    const response = await instance({
      method: "DELETE",
      url: "/certificate/" + elementId,
    });
    return response;
  } catch (err) {}
};
export const certificateUpdate = async (data, itemId) => {
  try {
    const response = await instance({
      method: "POST",
      url: "/language-certificate/update/" + itemId,
      data: data,
    });
    return response;
  } catch (err) {}
};
