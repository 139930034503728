import React from "react";
import { Button, Image, Layout, Avatar, Tooltip, Dropdown, Menu } from "antd";
import { AiOutlineAlignLeft, AiOutlineAlignRight } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { REMOVE_USER } from "../../../../redux/user/actionType";
import instance from "../../../../api/axios";
import { Select } from "antd";
import ReactCountryFlag from "react-country-flag";
import { useState } from "react";
import i18n from "../../../../i18next";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const { Header } = Layout;
const { Option } = Select;

const CustomHeader = ({ setCollapsed, collapsed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lang, setLang] = useState(localStorage.getItem("i18lang"));
  const { t } = useTranslation();
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const langs = [
    {
      key: "uz",
      label: "O'zbekcha",
    },
    {
      key: "ru",
      label: "Pусский",
    },
  ];

  const onChange = (key) => {
    localStorage.setItem("i18lang", key);
    i18n.changeLanguage(key);
    setLang(key);
  };
  return (
    <Fragment>
      <Header className="site-layout-background fixed-top" style={{ transition: 'all 0.2s',zIndex:1, padding: 0, left: collapsed ? 0 : 200, }}>
        <div className="d-flex justify-content-between me-3">
          <div>
            <Button type="link" onClick={toggle}>
              {collapsed ? (
                <AiOutlineAlignLeft size={20} />
              ) : (
                <AiOutlineAlignRight size={20} />
              )}
            </Button>
          </div>

          <div className="float-end d-flex justify-content-center align-items-center">
            {/* Languages */}
            <Dropdown
              className="me-3 text-white"
              trigger={["hover"]}
              overlay={
                <Menu>
                  {langs.map((item) => {
                    return (
                      <Menu.Item
                        onClick={() => onChange(item?.key)}
                        key={item.key}
                      >
                        <ReactCountryFlag
                          countryCode={item.key}
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        />
                        {item.label}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              }
            >
              <Button type="link">
                <span className="d-flex align-items-center">
                  <ReactCountryFlag
                    className="me-2"
                    countryCode={i18next.language.toUpperCase()}
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                  />
                  {
                    langs?.filter((item) => item?.key === i18next.language)[0]
                      ?.label
                  }
                </span>
              </Button>
            </Dropdown>

            {/* Logout */}
            <Tooltip
              placement="bottom"
              title={t("Profildan chiqish")}
              color="blue"
            >
              <Button
                onClick={() => {
                  dispatch({ type: REMOVE_USER });
                  localStorage.clear();
                  instance({
                    method: "post",
                    url: "/auth/logout",
                  });
                  Swal.fire({
                    title: `${t("Tizimdan muvaffaqiyatli chiqdingiz!")}`,
                    showConfirmButton: false,
                    icon: "success",
                    background: "#fff",
                    grow: false,
                    width: "400px",
                    position: "center",
                    timer: 1000,
                    timerProgressBar: true,
                  }).then((r) => {
                    history.push("/login");
                  });
                }}
                type="button"
                className="text-danger"
                size="small"
              >
                {t("Chiqish")}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Header>
    </Fragment>
  );
};

export default CustomHeader;
