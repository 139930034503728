import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import instance from "../../../api/axios";

const { Option } = Select;

const CreateSubject = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    let data = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value || key == "status") {
        if (key == "status") {
          data.append(key, value ? 1 : 0);
        } else {
          data.append(key, value);
        }
      }
    });

    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: "/subjects",
        data: data,
      });
      history.push("/subjects");
    } catch (error) {
      setLoader(false);
      message.error("Fan saqlashda xatolik!");
    }
  };

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink type="link" to={"/"}>
                {t("Asosiy")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <NavLink type="link" to={"/subjects"}>
                {t("Fanlar")}
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("Qo'shish")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin tip={t("Fan saqlanmoqda...")} spinning={loader}>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <h3>{t("Fan qo'shish")}</h3>

          <Row className="add-edu-type" gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to`ldiring" },
                ]}
                name="name_uz"
                label={t("Nomi uz")}
              >
                <Input placeholder={t("o'zbekcha nomi")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to`ldiring" },
                ]}
                name="name_ru"
                label={t("Nomi ru")}
              >
                <Input placeholder={t("ruscha nomi")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="course" label={t("Kurs")}>
                <Select placeholder={t("kurs")}>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="description_uz" label={t("Tavsif uz")}>
                <Input placeholder={t("tavsif")}></Input>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="description_ru" label={t("Tavsif ru")}>
                <Input placeholder={t("tavsif")}></Input>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item name="is_active" label={t("Holati")}>
                <Switch defaultChecked />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24} className="text-end">
              <Button htmlType="reset" className="btn-danger" type="primary">
                {t("O'chirish")}
              </Button>
              <Button htmlType="submit" className="mx-3" type="primary">
                {t("Saqlash")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default CreateSubject;
