import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import instance from "../../../api/axios";

const CreateTexnikum = () => {
  // hooks
  const history = useHistory();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    let data = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value || key == "status") {
        if (key == "status") {
          data.append(key, value ? 1 : 0);
        } else {
          data.append(key, value);
        }
      }
    });

    try {
      setLoader(true);
      const resp = await instance({
        method: "post",
        url: "/texnikum",
        data: data,
      });
      history.push("/texnikumlar");
    } catch (error) {
      setLoader(false);
      message.error("Fan saqlashda xatolik!");
    }
  };

  return (
    <Fragment>
        <Row>
          <Col span={24}>
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink type="link" to={"/"}>
                    {t("Asosiy")}
                  </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                  <NavLink type="link" to={"/texnikumlar"}>
                    {t("Texnikumlar")}
                  </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item>{t("Texnikum qo'shish")}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Col>
        </Row>
      <Spin tip={t("Texnikum saqlanmoqda...")} spinning={loader}>

        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <h3>{t("Texnikum qo'shish")}</h3>

          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to`ldiring" },
                ]}
                name="name_uz"
                label={t("Nomi uz")}
              >
                <Input placeholder={t("o'zbekcha nomi")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to`ldiring" },
                ]}
                name="name_ru"
                label={t("Nomi ru")}
              >
                <Input placeholder={t("ruscha nomi")}></Input>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to`ldiring" },
                ]}
                name="description_uz"
                label={t("Tavsif uz")}
              >
                <Input placeholder={t("tavsif")}></Input>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Iltimos maydonlarni to`ldiring" },
                ]}
                name="description_ru"
                label={t("Tavsif ru")}
              >
                <Input placeholder={t("tavsif")}></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24} className="text-end">
              <Button htmlType="reset" className="btn-danger" type="primary">
                {t("O'chirish")}
              </Button>
              <Button htmlType="submit" className="mx-3" type="primary">
                {t("Saqlash")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default CreateTexnikum;
