import { Fragment, React } from "react";
import { useRef, useState, useEffect } from "react";
import "./login.scss";
import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Select,
  Dropdown,
  Menu,
} from "antd";
import loginImg from "../../assets/images/for login page.svg";
import { NavLink, useHistory } from "react-router-dom";
import instance from "../../api/axios";
import { useDispatch } from "react-redux";
import { SAVE_USER } from "../../redux/user/actionType";
import { MdVpnKey } from "react-icons/md";
import i18n from "../../i18next";
import { useTranslation } from "react-i18next";
import logoImg from "../../assets/images/TSUL.png";
import logoMinjust from "../../assets/images/minjust2.png";
import ReactCountryFlag from "react-country-flag";
import { AiOutlineInfoCircle } from "react-icons/ai";
import i18next from "i18next";
import { FaAngleDown } from "react-icons/fa";

const LOGIN_URL = "/auth/login";
const { Option } = Select;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("i18lang"));
  const { t } = useTranslation();

  useEffect(() => {
    userRef.current.focus();
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [user, password]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    try {
      setLoader(true);
      const data = new FormData();
      data.append("username", values?.username);
      data.append("password", values?.password);
      const response = await instance({
        url: LOGIN_URL,
        method: "post",
        data: data,
        headers: {
          Accept: "application/json",
        },
      });

      if (response?.data?.status === "Success") {
        localStorage.setItem("access_token", response?.data?.data?.token);
        instance.defaults.headers[`Authorization`] =
          "Bearer " + response?.data?.data?.token;
        form.resetFields();
        const me = await instance({
          url: "/me",
        });
        if (me?.status === 200) {
          dispatch({
            type: SAVE_USER,
            payload: me?.data,
            isAuth: true,
            role: me?.data?.role,
          });
          history.push("/");
        }
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Serverda uzilish!");
      } else if (err?.response?.status == 400) {
        setErrMsg("Foydalanuvchi nomi yoki parol xato!");
      } else if (err?.response?.status == 401) {
        message.error("Bunday foydalanuvchi nomi va parol mavud emas!");
      } else if (err?.response?.status === 422) {
        form?.setFields(
          Object.entries(err?.response?.data?.errors)?.map(([key, value]) => {
            return {
              name: key,
              errors: value,
            };
          })
        );
      } else {
        setErrMsg("Tizimga kirishda xatolik!");
      }

      setLoader(false);

      errRef.current.focus();
    }
  };

  const langs = [
    {
      key: "uz",
      label: "O'zbekcha",
    },
    {
      key: "ru",
      label: "Pусский",
    },
  ];

  const onChange = (key) => {
    localStorage.setItem("i18lang", key);
    i18n.changeLanguage(key);
    setLang(key);
  };

  return (
    <Fragment>
      <Spin tip={t("Yuklanmoqda...")} size="large" spinning={loader}>
        {/* Top login header */}

        <header class="shadow bg-white fixed-top py-3">
          <div
            class="container d-flex justify-content-between align-content-center"
            style={{ height: "80px" }}
          >
            <a className="navbar-brand d-flex" href="#">
              <img
                className="img-fluid h-100"
                src={logoMinjust}
                alt="network error"
              />{" "}
              <img
                className="img-fluid h-100"
                src={logoImg}
                style={{ height: "50px" }}
                alt="network error"
              />
            </a>

            <a className="d-flex justify-content-center align-items-center">
              <Dropdown
                icon={<FaAngleDown />}
                className="me-3"
                trigger={["hover"]}
                overlay={
                  <Menu>
                    {langs.map((item) => {
                      return (
                        <Menu.Item
                          onClick={() => onChange(item?.key)}
                          key={item.key}
                        >
                          <ReactCountryFlag
                            countryCode={item.key}
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                            }}
                          />
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
              >
                <Button type="link" size="small">
                  <span className="d-flex align-items-center">
                    <ReactCountryFlag
                      className="me-2"
                      countryCode={i18next.language.toUpperCase()}
                      svg
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                      }}
                    />
                    {
                      langs?.filter((item) => item?.key === i18next.language)[0]
                        ?.label
                    }
                  </span>
                </Button>
              </Dropdown>
            </a>
          </div>
        </header>

        {/* Login Box */}
        <div
          className="login-page"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <div className="login-box">
            <Form
              form={form}
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <h3 className="form-title my-3">
                {t(
                  "Yuridik texnikumlar bitiruvchilariga yo'llanmalar berish platformasi"
                )}
              </h3>
              <Form.Item
                label={t("Login")}
                name="username"
                rules={[
                  {
                    required: true,
                    message: `${t("Iltimos login kiriting!")}`,
                  },
                ]}
              >
                <Input
                  type="text"
                  ref={userRef}
                  placeholder={t("login")}
                  className="login-form-username"
                />
              </Form.Item>

              <Form.Item
                label={t("Parol")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: `${t("Iltimos parolni kiriting!")}`,
                  },
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder={t("parol")}
                  className="login-form-password"
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Kirish")}
              </Button>

              <div className="d-flex align-items-center justify-content-between mt-3 registr-link">
                <NavLink to={"/registration"}>{t("Ro'yxatdan o'tish")}</NavLink>
                <NavLink to={"/reset_password"}>
                  <MdVpnKey /> {t("Parolni tiklash")}
                </NavLink>
              </div>
            </Form>
            <div className="illustration-wrapper">
              <img
                className="img-fluid"
                style={{ width: "100%" }}
                src={loginImg}
                alt="Login"
              />
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default Login;
