import React from "react";
import { notification } from "antd";

const openNotification = (placement, message, duration) => {
  notification.success({
    message,
    placement,
    duration,
  });
};

export default openNotification;
