/** @format */

import { Card, Col, message, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaFileDownload, FaUserAltSlash, FaUserCheck, FaUserEdit, FaUserPlus } from "react-icons/fa";
import "../main/main.scss";
import { useEffect, useState } from "react";
import instance from "../../api/axios";
import { NavLink } from "react-router-dom";

const CheckerDashboard = () => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState([
    {
      name: "Jami arizalar",
      count: 0,
      icon: <FaFileDownload className='icon-main' />
    },
    {
      name: "Yangi topshirilgan",
      count: 0,
      icon: <FaUserPlus className='icon' />
    },
    {
      name: "Qaytarilgan",
      count: 0,
      icon: <FaUserAltSlash className='icon' />
    },
    {
      name: "O'zgartirilgan",
      count: 0,
      icon: <FaUserEdit className='icon' />
    },
    {
      name: "Qabul qilingan",
      count: 0,
      icon: <FaUserCheck className='icon' />
    },
    {
      name: "Tasdiqlanmagan",
      count: 0,
      icon: <FaUserAltSlash className='icon' />
    },
  ]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await instance({
          method: "get",
          url: "/checker/badge/reports",
        });

        statistics.map((e, i) => {
          e.count = resp.data.data[i];
        });
        setStatistics([...statistics]);
      } catch (error) {
        message.error("Malumot olishda xatolik!");
      }
    })();
  }, []);

  const changeStatus = (status) => {
    sessionStorage.setItem("checker_tab_status", status);
  }

  return (
    <div className='statistics'>
          <Row gutter={24}>
            {statistics?.length
              ? statistics?.map((e, i) => (
                  <Col
                    key={i}
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    className='statics-wrapper'>
                    <Card
                      className='card-statics'
                      title={
                        <NavLink to={"/checker"}>
                          <div className='statics-title'>
                            <h4>
                              {t(e?.name)}
                              {": "}
                              <span>{e?.count}</span>
                            </h4>
                            {e?.icon}
                          </div>
                        </NavLink>
                      }
                      style={{ width: "100%" }}></Card>
                  </Col>
                ))
              : null}
      </Row>
    </div>
  );
};

export default CheckerDashboard;
