import React from "react";
import { Fragment } from "react";
import Reports from "../reports/Reports";
import ApplicantUi from "./../applicant/ApplicantUi";
import { useSelector } from "react-redux";
import TexAdminUI from "../student_base/texAdminUi";
import CheckerDashboard from "../checker/dashboard";

const MainPage = () => {
  const auth = useSelector((state) => state.authRedux);

  return (
    <Fragment>
      {auth?.role?.includes("super-admin") && <Reports />}
      {auth?.role?.includes("checker") && <CheckerDashboard />}
      {auth?.role?.includes("receiver") && <Reports />}
      {auth?.role?.includes("receiver_malaka_oshirish") && <Reports />}
      {auth?.role?.includes("applier") && <ApplicantUi />}
      {auth?.role?.includes("texnikum-admin") && <TexAdminUI />}
      {auth?.role?.includes("texnikum-comission") && <Reports />}
    </Fragment>
  );
};

export default MainPage;
