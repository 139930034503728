import Applicant from "../pages/applicant/ApplyDocs";
import Checker from "../pages/checker/AllDocs";
import { HiDocumentText, HiOutlineDocumentDownload } from "react-icons/hi";
import Login from "../pages/login/Login";
import ApplicantViewer from "../pages/checker/applicant viewer/ApplicantViewer";
import MainPage from "../pages/main";
import {
  FaHome,
  FaReadme,
  FaLanguage,
  FaUserEdit,
  FaUsers,
  FaUserCheck,
} from "react-icons/fa";
import { SiReadthedocs } from "react-icons/si";
import { BiError } from "react-icons/bi";
import { GiBookshelf } from "react-icons/gi";
import { BsBuilding, BsFileEarmarkCheckFill } from "react-icons/bs";
import { MdOutlinePlayLesson } from "react-icons/md";
import ApplicantUi from "../pages/applicant/ApplicantUi";
import EducationTypes from "../pages/education types/EducationTypes";
import EducationLanguage from "../pages/education languages/EducationLanguage";
import CreateEduTypes from "../pages/education types/crud/CreateEduTypes";
import EditEduTypes from "../pages/education types/crud/EditEduTypes";
import CreateEduLangs from "../pages/education languages/crud/CreateEduLangs";
import EditEduLangs from "../pages/education languages/crud/EditEduLangs";
import Faculties from "../pages/faculty/Faculties";
import CreateFaculty from "../pages/faculty/crud/CreateFaculty";
import EditFaculty from "../pages/faculty/crud/EditFaculty";
import Registration from "../pages/login/Registration/Registration";
import ResetPassword from "../pages/login/Registration/ResetPassword";
import LoginSetting from "../pages/login setting/LoginSetting";
import Requirements from "../pages/reports/Requirements";
import Subjects from "../pages/subject/Subjects";
import CreateSubject from "../pages/subject/crud/CreateSubject";
import EditSubject from "../pages/subject/crud/UpdateSubject";
import Texnikumlar from "../pages/texnikum/Texnikumlar";
import CreateTexnikum from "../pages/texnikum/crud/CreateTexnikum";
import ReadTexnikum from "../pages/texnikum/crud/ReadTexnikum";
import UpdateTexnikum from "../pages/texnikum/crud/UpdateTexnikum";
import StudentChecking from "../pages/student_base/StudentChecking";
import StudentBace from "../pages/student_base";
import CertificatesCrud from "../pages/certificates/CertificatesCrud";
import OnlineLessons from "../pages/online_lessons/OnlineLessons";
import TexComissionUI from "../pages/texnicum_comission";
import TexComissionView from "../pages/texnicum_comission/view";
import ConversationScore from "../pages/conversation_ball";
import ConversationScoreView from "../pages/conversation_ball/view";
import StatisticForChecker from "../pages/checker/statistic/StatisticForChecker";

export const routes = [
  {
    name: "Asosiy",
    path: "/",
    component: MainPage,
    icon: <FaHome />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: [
        "super-admin",
        "texnikum-admin",
        "receiver",
        "receiver_malaka_oshirish",
        "applier",
        "checker",
        "texnikum-comission",
      ],
    },
  },
  {
    name: "Ta'lim tili",
    path: "/education_language",
    component: EducationLanguage,
    icon: <FaLanguage />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create edu langs",
    path: "/create_edu_langs",
    component: CreateEduLangs,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit edu langs",
    path: "/edit_edu_langs/:id",
    component: EditEduLangs,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Ta'lim shakli",
    path: "/education_types",
    component: EducationTypes,
    icon: <FaReadme />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create edu types",
    path: "/create_edu_types",
    component: CreateEduTypes,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit edu types",
    path: "/edit_edu_types/:id",
    component: EditEduTypes,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Fakultetlar",
    path: "/faculty",
    component: Faculties,
    icon: <SiReadthedocs />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create faculty",
    path: "/create_faculty",
    component: CreateFaculty,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit faculty",
    path: "/edit_faculty/:id",
    component: EditFaculty,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Fanlar",
    path: "/subjects",
    component: Subjects,
    icon: <GiBookshelf />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create subject",
    path: "/create_subject",
    component: CreateSubject,
    icon: <GiBookshelf />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit subject",
    path: "/edit_subject/:id",
    component: EditSubject,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Texnikumlar",
    path: "/texnikumlar",
    component: Texnikumlar,
    icon: <BsBuilding />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Create texnikum",
    path: "/create_texnikum",
    component: CreateTexnikum,
    icon: <GiBookshelf />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Edit texnikum",
    path: "/edit_texnikum/:id",
    component: UpdateTexnikum,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Read texnikum details",
    path: "/read_texnikum/:id",
    component: ReadTexnikum,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Talabalar bazasi",
    path: "/talabalar_bazasi",
    component: StudentBace,
    icon: <FaUsers />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["texnikum-admin"],
    },
  },
  {
    name: "Talabani tasdiqlash",
    path: "/student_check/:id",
    component: StudentChecking,
    icon: <FaUsers />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin", "texnikum-admin"],
    },
  },
  {
    name: "Topshirish qoidalari",
    path: "/applying_requirements",
    component: Requirements,
    icon: <BiError />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin"],
    },
  },
  {
    name: "Hujjatlarni tekshirish",
    path: "/checker",
    component: Checker,
    icon: <BsFileEarmarkCheckFill />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: [
        "super-admin",
        "receiver",
        "receiver_malaka_oshirish",
        "checker",
        // "texnikum-admin",
      ],
    },
  },
  {
    name: "Statistika",
    path: "/applications-statistics-for-checker",
    component: StatisticForChecker,
    icon: <HiDocumentText />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["checker"],
    },
  },
  {
    name: "ApplicantViewer",
    path: "/applicant_viewer/:id",
    component: ApplicantViewer,
    icon: null,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: [
        "super-admin",
        "receiver",
        "receiver_malaka_oshirish",
        "checker",
        "texnikum-admin",
      ],
    },
  },
  {
    name: "Hujjat topshirish",
    path: "/applicant/:tab",
    component: Applicant,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["applier"],
    },
  },
  {
    name: "ApplicantUi",
    path: "/applicantui",
    component: ApplicantUi,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin", "applier"],
    },
  },
  {
    name: "Texnicum comission",
    path: "/tex_comission_ui",
    component: TexComissionUI,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["super-admin", "texnikum-comission"],
    },
  },
  {
    name: "Texnicum comission view",
    path: "/tex_comission_view/:id",
    component: TexComissionView,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["super-admin", "texnikum-comission"],
    },
  },
  {
    name: "Suhbatni baholash",
    path: "/conversation_score",
    component: ConversationScore,
    icon: <FaUserCheck />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["texnikum-admin"],
    },
  },
  {
    name: "Suhbatni baholashni ko'rish",
    path: "/conversation_score_view/:id",
    component: ConversationScoreView,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: true,
      key: ["texnikum-admin"],
    },
  },
  {
    name: "Video qo'llanmalar",
    path: "/video_lessons",
    component: OnlineLessons,
    icon: <MdOutlinePlayLesson />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["texnikum-admin", "applier", "checker"],
    },
  },
  {
    name: "Profil sozlamalari",
    path: "/edit_profil",
    component: LoginSetting,
    icon: <FaUserEdit />,
    config: {
      showLink: true,
      exact: true,
      layout: true,
      key: ["applier", "texnikum-admin", "checker"],
    },
  },
];

export const public_routes = [
  {
    name: "Registration",
    path: "/registration",
    component: Registration,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: false,
      key: ["applier"],
    },
  },
  {
    name: "Reset password",
    path: "/reset_password",
    component: ResetPassword,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: false,
      key: ["applier"],
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    icon: <HiOutlineDocumentDownload />,
    config: {
      showLink: false,
      exact: true,
      layout: false,
      key: [""],
    },
  },
];
