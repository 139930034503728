import React from "react";
import instance from "../../api/axios";

export const applicantIndex = async (params = {}) => {
  try {
    const response = await instance({
      method: "GET",
      url: "/application?expand=region,education_language,education_type,universities",
    });
    return response;
  } catch (err) {return err}
};