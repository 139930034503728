import { Switch, Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import RenderComponent from "./render";
import { public_routes, routes } from "./routes";
import Login from "../pages/login/Login";

const RoutesMiddleware = () => {
  const auth = useSelector((state) => state.authRedux);
  console.log(auth);
  if (auth?.isAuthenticated) {
    return (
      <Switch>
        {routes.length &&
          routes.map((element, index) => {
            if (
              element?.config.key.includes("*") ||
              element?.config?.key?.includes(auth?.role[0])
            ) {
              return (
                <RenderComponent
                  key={index}
                  path={element.path}
                  component={element.component}
                  layout={element?.config?.layout}
                  exact={element.config.exact}
                />
              );
            }
          })}
      </Switch>
    );
  }
  return (
    <Switch>
      {public_routes?.map((element,index) => {
        return (
          <RenderComponent
            key={index}
            path={element.path}
            component={element.component}
            layout={element?.config?.layout}
            exact={element.config.exact}
          />
        );
      })}
    </Switch>
  );
};

export default RoutesMiddleware;
