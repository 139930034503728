import axios from "axios";
import i18n from "../i18next";
import { apiBugStore } from "../services/api_services/api_bug";

const instance = axios.create({
  baseURL: "https://qabulapi.tsul.uz/api",
});

if (localStorage.getItem("access_token"))
  instance.defaults.headers[`Authorization`] =
    "Bearer " + localStorage.getItem("access_token");
// instance.defaults.headers[`Accept-Language`] = localStorage.getItem('i18lang') ? localStorage.getItem('i18lang') : 'uz'
instance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Accept-Language": localStorage.getItem("i18lang")
        ? localStorage.getItem("i18lang")
        : "uz",
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with request error
    let data = JSON.stringify(error?.response);
    // apiBugStore(data);
    return Promise.reject(error);
  }
);
export default instance;
